import React, { Fragment, useCallback, useEffect, useState } from 'react';

import { Progressbar } from '../CreatePage/StepThreeUploads/Progressbar';
import { UploadError } from '../CreatePage/StepThreeUploads/UploadError';
import axios, {CancelToken} from 'axios';
import Bugsnag from '@bugsnag/js';
import { uploadConfig } from '../../utils/upload_config';
import { ErrorBanner } from '../CreatePage/StepThreeUploads/ErrorBanner';
import { ListImageContainer } from './ImageUpload/CustomImageList/index.component';
import { CustomDropzone } from './ImageUpload/CustomDropzone/index.component';
import { uniqueId } from '../../utils/functions'

import { Link } from 'react-router-dom';

import logo from '../../styles/wedmatchlogo.png';


import s from './style.module.css';

import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    // DragOverlay,
} from '@dnd-kit/core';

import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    // rectSwappingStrategy,
    rectSortingStrategy,
    // verticalListSortingStrategy
} from '@dnd-kit/sortable';

const cloudinary_preset = 'comparison';
const cloudName = 'dfjwlskop';

const VendorForm = (props) => {
    const uploadUrl = `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`;

    const { id } = props.match.params;

    const [isAuthorized, setIsAuthorized] = useState(true);
    const [emailField, setEmailField] = useState(false);
    const [email, setEmail] = useState('');

    const [vendorDetails, setVendorDetails] = useState({
      id: id,
      businessName: '',
      category: '',
      location: '',
      email: '',
      website: '',
      instagram: '',
      phoneNumber: '',
      streetAddress: '',
      city: '',
      state: '',
      zip: '',
      reviewLink: '',
      startingPrice: '',
      videoUrls: ['', '', '', '', ''],
    });

    const [vendorProfileImageDisplay, setVendorProfileImageDisplay] = useState('https://placehold.co/100');
    const [vendorProfileImage, setVendorProfileImage] = useState({ url: '', width: 0, height: 0 });

    const [photos, setPhotos] = useState([]);
    const [sortableArray, setSortableArray] = useState(Object.keys(photos));
    const [activeId, setActiveId] = useState(null);
    const [totalProgress, setTotalProgress] = useState(0);
    const [uploadSection, setUploadSection] = useState(false);
    const [maxSizeError, setMaxSizeError] = useState([]);
    const [networkOnline, setNetworkOnline] = useState(true);
    const [thumbnails, setThumbnails] = useState([])

    const [uploadSuccessChecker, setUploadSuccessChecker] = useState({
        success: 0,
        error: 0,
    });

    const [notification, setNotification] = useState({ visible: false, type: "", message: "" });

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [accessButtonDisabled, setAccessButtonDisabled] = useState(false);

    const [message, setMessage] = useState('');

    let counter = 0;

  const handleAccessRequest = () => {
    setNotification({ visible: false, type: "", message: "" });
    setAccessButtonDisabled(true);
    if(emailField) {
      if (!email || !email.includes('@')) {
        setNotification({
          visible: true,
          type: "error",
          message: "Please enter your email address."
        });
        setAccessButtonDisabled(false);
        return;
      }
    }

    axios.post('/api/refresh-vendor-code', { id: id, email: email })
        .then(response => {
            setMessage('A link has been sent to your email. Please follow the link to access your dashboard.');
        })
        .catch(error => {
            console.log(error.message);
            setMessage('Failed to request access. Refresh the page and please try again.');
        });
};


  const onDrop = useCallback(acceptedFiles => {
    let allImages = {};

    acceptedFiles.forEach((file, index) => {
      let id = uniqueId();
      if (file.size > uploadConfig.MAX_SIZE_OF_PHOTO) {
        setMaxSizeError(prev => [...prev, { id, err: true }]);
      } else {
        setMaxSizeError(prev => [...prev, { id, err: false }]);
      }
      let imgObj = {
        id,
        imageFile: file,
        preview: URL.createObjectURL(file),
        progress: 0,
        uploaded: false,
        allowed: !(file.size > uploadConfig.MAX_SIZE_OF_PHOTO),
        error: false,
      };
      allImages = {
        ...allImages,
        [id]: imgObj,
      };
    });

    setPhotos(prev => {
      return { ...prev, ...allImages };
    });

    setUploadSection(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
      transition: {
        duration: 150, // milliseconds
        easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
      },
    })
  );

  const totalImages = Object.keys(photos).length;

  const handleDragStart = event => {
    const { active } = event;

    setActiveId(active.id);
  };

  const onDragEnd = result => {
    const { active, over } = result;
    if (active.id !== over.id) {
      setSortableArray(items => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }

    setActiveId(null);
  };

  const cancelAll = () => {
    Object.entries(photos).forEach(([key, item]) => {
      if (!item.uploaded) {
        if (item.cancel) {
          item.cancel('cancelled by user');
        }
        removeItem(key);
      }
    });
  };

  const removeItem = (id, deleteFromCloudinary) => {
    if (deleteFromCloudinary) {
      setSortableArray(prev => prev.filter(i => i !== id));
      setPhotos(prev => {
        const { [id]: removedProperty, ...rest } = prev;
        return {
          ...rest,
        };
      });
    } else {
      setMaxSizeError(prev => [...prev.filter(i => i.id !== id)]);
      if (photos[id].cancel) photos[id].cancel('Cancelled by user!');
      setSortableArray(prev => prev.filter(i => i !== id));
      setPhotos(prev => {
        const { [id]: removedProperty, ...rest } = prev;
        return {
          ...rest,
        };
      });
    }
  };

  const sanitizeName = (name) => {
    return name.replace(/[^a-zA-Z0-9\s]/g, '').replace(/\s+/g, '_').toLowerCase();
  }

  const uploadEachImage = item => {
    if (item.allowed === true) {
      if (item.started !== true) {
        // SET STARTED UPLOADING
        setPhotos(prev => {
          return {
            ...prev,
            [item.id]: {
              ...prev[item.id],
              started: true,
            },
          };
        });
        const config = {
          onUploadProgress: function (progressEvent) {
            setProgressOfEachImage(item.id, Math.round((progressEvent.loaded * 100) / progressEvent.total));
          },
          cancelToken: new CancelToken(cancel => {
            setPhotos(prev => {
              return {
                ...prev,
                [item.id]: {
                  ...prev[item.id],
                  cancel: cancel,
                },
              };
            });
          }),
        };
        const data = new FormData();
        data.append('file', item.imageFile);
        data.append('upload_preset', cloudinary_preset);

        const sanitizedBusinessName = sanitizeName(vendorDetails.businessName);
        const sanitizedLocation = sanitizeName(vendorDetails.location);
        const sanitizedCategory = sanitizeName(vendorDetails.category);

        const baseName = `${sanitizedBusinessName}-${sanitizedLocation}-${sanitizedCategory}`;
        const uniqueID = `${Date.now()}-${counter++}`;
        const customName = `${baseName}-${uniqueID}`;

        data.append('public_id', customName);

        axios
          .post(uploadUrl, data, config)
          .then(response => {
            if (response.status === 200) {
              controlSuccessAndErrorUpload('success');
              setUploadedImages(item.id, response.data);
            }
          })
          .catch(err => {
            if (axios.isCancel(err)) {
              console.log('Request canceled', err.message);

              Bugsnag.notify(err);
            } else {
              let errorMessage = err?.response?.data?.error?.message;

              Bugsnag.notify(err, event => {
                event.addMetadata('details', err?.response?.data);
              });

              controlSuccessAndErrorUpload('error');
              setUploadError(item.id, errorMessage);
            }
          });
      }
    }
  };

  const uploadNow = () => {
    let moreThan15 = Object.keys(photos).length > 15;
    if (moreThan15) {
      setNotification({
        visible: true,
        type: "error",
        message: "You can only upload 15 photos at a time."
      });
      return;
    }
    Object.entries(photos).forEach(([key, item]) => {
      uploadEachImage(item);
    });
    controlTotalProgress();
  };

  const controlTotalProgress = () => {
    let total = 0;
    Object.entries(photos).forEach(([key, item]) => {
      total += item.progress;
    });
    setTotalProgress(Math.round(total / totalImages));
  };

// Set Logo
const setUploadedLogo = (data) => {
    setVendorProfileImage({
        url: data.url,
        width: data.width,
        height: data.height
    });
};

//   Logo upload
  const uploadLogo = item => {
    const data = new FormData();
    data.append('file', item);
    data.append('upload_preset', cloudinary_preset);

    const sanitizedBusinessName = sanitizeName(vendorDetails.businessName);
    const sanitizedLocation = sanitizeName(vendorDetails.location);
    const sanitizedCategory = sanitizeName(vendorDetails.category);

    const baseName = `profile-${sanitizedBusinessName}-${sanitizedLocation}-${sanitizedCategory}`;
    const uniqueID = Date.now();
    const customName = `${baseName}-${uniqueID}`;

    data.append('public_id', customName);

    axios.post(uploadUrl, data)
      .then(response => {
        if (response.status === 200) {
          setVendorProfileImageDisplay(response.data.url);
          setUploadedLogo(response.data);
        }
      })
      .catch(err => {
        console.error("Error uploading the logo:", err);
        Bugsnag.notify(err);
      });
  };

//   Delete Logo
  const deleteLogo = () => {
    setVendorProfileImageDisplay('https://placehold.co/100');
    setVendorProfileImage({ url: '', width: 0, height: 0 });
    document.getElementById("logo-upload").value = "";
  };

  const setProgressOfEachImage = (id, progress) => {
    setPhotos(prev => {
      return {
        ...prev,
        [id]: {
          ...prev[id],
          progress,
        },
      };
    });
  };

  const controlSuccessAndErrorUpload = (type, deleted) => {
    setUploadSuccessChecker(prev => {
      return {
        ...prev,
        [type]: deleted ? prev[type] - 1 : prev[type] + 1,
      };
    });
  };

  const setUploadedImages = (id, data) => {
    setPhotos(prev => {
      return {
        ...prev,
        [id]: {
          ...prev[id],
          imgUrl: data.url,
          uploaded: true,
          cloudinaryResponse: data,
        },
      };
    });
  };

  const setUploadError = (id, msg) => {
    setPhotos(prev => {
      return {
        ...prev,
        [id]: {
          ...prev[id],
          error: true,
          uploaded: false,
          msg,
        },
      };
    });
  };

  const calculateMaxSizeError = () => {
    let error = maxSizeError.map(item => item.err);
    return error.includes(true);
  };

  const isValidPhoneNumber = (phoneNumber) => {
    const pattern = /^[0-9]{10}$/;
    return pattern.test(phoneNumber);
  }

  const isValidVideoURL = (url) => {
    const pattern = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be|vimeo\.com)\/.+$/;
    return pattern.test(url);
  }

  const isValidInstagramURL = (url) => {
    const pattern = /^(https?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9_](?:(?:[a-zA-Z0-9_]|(?:\.(?!\.))){0,28}(?:[a-zA-Z0-9_]))?/;
    return pattern.test(url);
  }

  const isValidWebsiteURL = (url) => {
    const pattern = /^(https?:\/\/)?[\w\-~]+(\.[\w\-~]+)+(\/[\w\-~@:%]*)*(#[\w\-]*)?(\?[a-zA-Z0-9_%.~+=\-]*)?$/;
    return pattern.test(url);
  }

  const submitForm = (e) => {
    e.preventDefault()

    setNotification({ visible: false, type: "", message: "" });

    if (vendorDetails.phoneNumber && !isValidPhoneNumber(vendorDetails.phoneNumber)) {
      setNotification({
        visible: true,
        type: "error",
        message: "Phone number should be 10 digits."
      });
      return;
    }

    const nonEmptyUrls = vendorDetails.videoUrls.filter(url => url.trim() !== "");

    if (!nonEmptyUrls.every(isValidVideoURL)) {
      setNotification({
        visible: true,
        type: "error",
        message: "Please provide a valid Youtube/Vimeo video URL."
      });
      return;
    }

    if (vendorDetails.instagram && !isValidInstagramURL(vendorDetails.instagram)) {
      setNotification({
        visible: true,
        type: "error",
        message: "Please provide a full Instagram URL."
      });
      return;
    }

    if (vendorDetails.website && !isValidWebsiteURL(vendorDetails.website)) {
      setNotification({
        visible: true,
        type: "error",
        message: "Please provide valid website URL for the website link."
      });
      return;
    }

    if (vendorDetails.reviewLink && !isValidWebsiteURL(vendorDetails.reviewLink)) {
      setNotification({
        visible: true,
        type: "error",
        message: "Please provide valid website URL for the review link."
      });
      return;
    }

    setButtonDisabled(true);

    const sortedPhotos = Object.values(photos).map(p => {
      return {...p, sortPriority: sortableArray.indexOf(p.id) + 1 }
    })

    axios.post('/api/updatevendors', { id, photos: sortedPhotos, vendorProfileImage, ...vendorDetails })
    .then(response => {
      setNotification({
        visible: true,
        type: "success",
        message: "Your changes have been saved."
      });
      setUploadSection(false);
      setTotalProgress(0);
      setThumbnails([]);
      setUploadSuccessChecker({
        success: 0,
        error: 0,
      });
      setMaxSizeError([]);
      setSortableArray(Object.keys(photos));
      setPhotos([]);
      setButtonDisabled(false);
    })
    .catch(error => {
      console.log(error.message)
      setNotification({
        visible: true,
        type: "error",
        message: "There was an error saving your changes."
      });
      setButtonDisabled(false);
    });
  }

  useEffect(() => {
    uploadNow();
  }, [photos]);

  useEffect(() => {
    setSortableArray(Object.keys(photos))
    if (Object.keys(photos).length !== 0) setUploadSection(true);
    if (Object.keys(photos).length === 0) setUploadSection(false);
  }, [photos]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (code) {
        document.cookie = `vendorCode=${code}; max-age=${5 * 24 * 60 * 60}; path=/`;
    }

    const handleOffline = () => {
      setNetworkOnline(false);
    };

    const handleOnline = () => {
      setNetworkOnline(true);
    };

    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);

    return () => {
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
    };
  }, []);

  useEffect(() => {
    // Assuming you're using axios for HTTP requests
    console.log("Running the authorization check");
    axios.get(`/api/vendor/${props.match.params.id}`)
        .then(response => {
          const videoUrlsFromBackend = Array.isArray(response.data.video_urls) ? response.data.video_urls.filter(url => url).map(url => "https://www.youtube.com/watch?v=" + url) : [];
          const totalBoxes = 5;
          const emptyBoxes = new Array(totalBoxes - videoUrlsFromBackend.length).fill('');
          const finalVideoUrls = [...videoUrlsFromBackend, ...emptyBoxes];
          setVendorDetails({
            businessName: response.data.business_name || '',
            email: response.data.contact_email || '',
            website: response.data.website_url || '',
            instagram: response.data.instagram_url || '',
            phoneNumber: response.data.phone_number || '',
            streetAddress: response.data.street_address || '',
            city: response.data.city || '',
            state: response.data.state || '',
            zip: response.data.zip_code || '',
            reviewLink: response.data.review_link || '',
            startingPrice: response.data.price || '',
            videoUrls: finalVideoUrls,
            category: response.data.category_name || '',
            location: response.data.location_name || '',
          });

          if(response.data.profile_image_url) {
            setVendorProfileImageDisplay(response.data.profile_image_url);
          }
          setVendorProfileImage({ url: response.data.profile_image_url, width: response.data.profile_image_width, height: response.data.profile_image_height })
        })
        .catch(error => {
          // if 401 error
          if (error.response && error.response.status === 401) {
            axios.post(`/api/check-vendor-email`, { id: id })
              .then(response => {
                if (!response.data.hasEmail) {
                  setEmailField(true);
                }
              })
              .catch(error => {
                console.log(error.message);
              });
          }
          console.log(error.message)
          setIsAuthorized(false);
        });
  }, [props.match.params.id]);

  return (
    <main className={s.vendor_dashboard}>
      <div className='light-blue-background'>
        <div className='wedding-upload-navbar'>
          <div className={`${s.nav}`}>
            <div className={s.logo}><img src={logo} /></div>
            <div className='simple-nav-links'>
              <a className='simple-nav-link' href='https://wedmatch.com'>Home</a>
            </div>
          </div>
        </div>
      </div>
      { isAuthorized ? (
        <div className={s.container}>
            {notification.visible &&
              <div className={`${s.notification} ${s[notification.type]}`}>
                  <span>{notification.message}</span>
                  <span className={s.close_button} onClick={() => setNotification({ ...notification, visible: false })}>X</span>
              </div>
            }
            {!uploadSection ? (
                <CustomDropzone photos={photos} onDrop={onDrop} />
            ) : (
                <DndContext sensors={sensors} collisionDetection={closestCenter} onDragStart={handleDragStart} onDragEnd={onDragEnd}>
                    <div>
                    {totalProgress === 100 ? (
                        <div className="progress_box">
                        <img style={{ marginBottom: 31 }} src="/images/vendor-img/upload-success.svg" alt="Upload Success" />
                        <div className="font24 bold600 text-center">
                            Successfully Uploaded {uploadSuccessChecker.success} Photos
                        </div>
                        {uploadSuccessChecker.error > 0 && (
                            <div className="font22 bold500 text-center text-warning">
                            Error in uploading {uploadSuccessChecker.error} Photos
                            </div>
                        )}
                        <button
                            onClick={e => {
                            e.preventDefault();
                            setUploadSection(false);
                            }}
                            className="add_more_btn mt-30"
                        >
                            Add More Photos
                        </button>
                        </div>
                    ) : (
                        <div className="progress_box">
                        <div className="font24 bold600 text-center">
                            Uploading {totalImages} Photo(s)
                        </div>
                        <div className="progress_bar_section">
                            <div className="font12 bold400 d-flex justify-content-between">
                            <div className="d-flex align-items-center text-light-grey">
                                <span>{totalProgress}%</span>
                                {/* <img
                                                    style={{margin:"0 10px"}}
                                                    className='d-block'
                                                    src='/images/icons/upload-dot.svg'
                                                    alt='Pause Icon'
                                                /> */}
                                {/* <span>{timeRemaining} remaining</span> */}
                            </div>
                            <div className="d-flex align-items-center">
                                {/* <div className='circle-29 pause_bg'>
                                                    <img
                                                        src='/images/icons/pause-icon.svg'
                                                        alt='Pause Icon'
                                                    />
                                                </div> */}
                                <div onClick={() => cancelAll()} className="circle-29 cancel_bg">
                                <img src="/images/icons/cancel-icon.svg" alt="Cancel Cross" />
                                </div>
                            </div>
                            </div>
                            <Progressbar progress={totalProgress} />
                        </div>
                        {!networkOnline && <UploadError uploadNow={uploadNow} />}
                        </div>
                    )}
                    {totalImages > uploadConfig.VENDORS_MAX_NUMBER_OF_PHOTOS && (
                        <ErrorBanner
                        total={totalImages}
                        type="max_photos" // max_photos || max_size
                        />
                    )}
                    {calculateMaxSizeError() && (
                        <ErrorBanner
                        total={totalImages}
                        type="max_size" // max_photos || max_size
                        />
                    )}
                    <SortableContext items={sortableArray} strategy={rectSortingStrategy}>
                        <div className="images_list_container">
                        {sortableArray.map((key, index) => (
                            <Fragment key={index}>
                            <ListImageContainer
                                selectMode={true}
                                thumbnails={thumbnails}
                                setThumbnails={setThumbnails}
                                item={photos[key]}
                                id={key}
                                removeItem={removeItem}
                                uploadEachImage={uploadEachImage}
                                index={index}
                                extra={index > 74 || photos[key]?.imageFile?.size > uploadConfig.MAX_SIZE_OF_PHOTO}
                                // deleteItem={deleteItem}
                            />
                            </Fragment>
                        ))}
                        </div>
                    </SortableContext>
                    {/* </DragDropContext> */}
                    </div>
                </DndContext>
            )}

            <section>
                <h2 className={s.vendor_dashboard__subheader}>Headshot or logo</h2>
                <div className={`${s.flex } ${s.flex__align_center} ${s.vendor_dashboard__logo_form}`}>
                    <img src={vendorProfileImageDisplay} alt="Vendor Logo" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
                    <input type="file" onChange={(e) => uploadLogo(e.target.files[0])} style={{ display: 'none' }} id="logo-upload" />
                    <label htmlFor="logo-upload">Change Image</label>
                    <button onClick={deleteLogo}>Delete Image</button>
                </div>
            </section>


            <section>
                <h2 className={s.vendor_dashboard__subheader}>Business Details</h2>
                <form className={s.vendor_dashboard__form}>

                    <div className={`${s.flex} ${s.flex__justify_between}`}>
                        <div style={{width: "48%"}} className={`floating_inputs_wrapper mb-0 mb-md-20 d-flex transparent_background_padding ${s.vendor_dashboard__input}`}>
                            <label htmlFor='businessName' className='signup-input-label-special'>
                                Business Name
                            </label>
                            <input
                                autocomplete="off"
                                placeholder='Business Name'
                                style={{
                                    width:"100%",
                                    // margin:'0 10px'
                                }}
                                value={vendorDetails.businessName}
                                onChange={e => setVendorDetails({...vendorDetails, businessName: e.target.value })}
                                name='businessName'
                                type='text'
                            />
                        </div>
                        <div style={{width: "48%"}} className={`floating_inputs_wrapper mb-0 mb-md-20 d-flex transparent_background_padding ${s.vendor_dashboard__input}`}>
                            <label htmlFor='email' className='signup-input-label-special'>
                                Email
                            </label>
                            <input
                                autocomplete="off"
                                placeholder='Email'
                                style={{
                                    width:"100%",
                                    // margin:'0 10px'
                                }}
                                value={vendorDetails.email}
                                onChange={e => setVendorDetails({...vendorDetails, email: e.target.value })}
                                name='email'
                                type='email'
                            />
                        </div>
                    </div>

                    <div className={`${s.flex} ${s.flex__justify_between}`}>
                        <div style={{width: "48%"}} className={`floating_inputs_wrapper mb-0 mb-md-20 d-flex transparent_background_padding ${s.vendor_dashboard__input}`}>
                            <label htmlFor='website' className='signup-input-label-special'>
                                Website
                            </label>
                            <input
                                autocomplete="off"
                                placeholder='Website'
                                style={{
                                    width:"100%",
                                    // margin:'0 10px'
                                }}
                                value={vendorDetails.website}
                                onChange={e => setVendorDetails({...vendorDetails, website: e.target.value })}
                                name='website'
                                type='text'
                            />
                        </div>
                        <div style={{width: "48%"}} className={`floating_inputs_wrapper mb-0 mb-md-20 d-flex transparent_background_padding ${s.vendor_dashboard__input}`}>
                            <label htmlFor='instagram' className='signup-input-label-special'>
                                Instagram
                            </label>
                            <input
                                autocomplete="off"
                                placeholder='Instagram'
                                style={{
                                    width:"100%",
                                    // margin:'0 10px'
                                }}
                                value={vendorDetails.instagram}
                                onChange={e => setVendorDetails({...vendorDetails, instagram: e.target.value })}
                                name='instagram'
                                type='text'
                            />
                        </div>
                    </div>

                    <div style={{flex:1}} className={`floating_inputs_wrapper mb-0 mb-md-20 d-flex transparent_background_padding ${s.vendor_dashboard__input}`}>
                        <label htmlFor='phoneNumber' className='signup-input-label-special'>
                            Phone Number
                        </label>
                        <input
                            autocomplete="off"
                            placeholder='Phone Number'
                            style={{
                                width:"100%",
                                // margin:'0 10px'
                            }}
                            value={vendorDetails.phoneNumber}
                            onChange={e => setVendorDetails({...vendorDetails, phoneNumber: e.target.value })}
                            name='phoneNumber'
                            type='number'
                        />
                    </div>

                    <div style={{flex:1}} className={`floating_inputs_wrapper mb-0 mb-md-20 d-flex transparent_background_padding ${s.vendor_dashboard__input}`}>
                        <label htmlFor='streetAddress' className='signup-input-label-special'>
                            Street address
                        </label>
                        <input
                            autocomplete="off"
                            placeholder='Street address'
                            style={{
                                width:"100%",
                                // margin:'0 10px'
                            }}
                            value={vendorDetails.streetAddress}
                            onChange={e => setVendorDetails({...vendorDetails, streetAddress: e.target.value })}
                            name='streetAddress'
                            type='text'
                        />
                    </div>

                    <div style={{flex:1}} className={`floating_inputs_wrapper mb-0 mb-md-20 d-flex transparent_background_padding ${s.vendor_dashboard__input}`}>
                        <label htmlFor='reviewLink' className='signup-input-label-special'>
                            Review Link
                        </label>
                        <input
                            autocomplete="off"
                            placeholder='Link to the page containing your wedding reviews'
                            style={{
                                width:"100%",
                                // margin:'0 10px'
                            }}
                            value={vendorDetails.reviewLink}
                            onChange={e => setVendorDetails({...vendorDetails, reviewLink: e.target.value })}
                            name='reviewLink'
                            type='text'
                        />
                    </div>

                    <div style={{flex:1}} className={`floating_inputs_wrapper mb-0 mb-md-20 d-flex transparent_background_padding ${s.vendor_dashboard__input}`}>
                        <label htmlFor='startingPrice' className='signup-input-label-special'>
                            Starting Price
                        </label>
                        <input
                            autocomplete="off"
                            placeholder='Starting price of your services...'
                            style={{
                                width:"100%",
                                // margin:'0 10px'
                            }}
                            value={vendorDetails.startingPrice}
                            onChange={e => setVendorDetails({...vendorDetails, startingPrice: e.target.value })}
                            name='startingPrice'
                            type='text'
                        />
                    </div>

                </form>
            </section>

            <section>
              <h2 className={s.vendor_dashboard__subheader}>Video Portfolio (optional)</h2>
              <form className={s.vendor_dashboard__form}>
                {vendorDetails.videoUrls.map((video, index) => (
                  <div
                    style={{ flex: 1 }}
                    className={`floating_inputs_wrapper mb-0 mb-md-20 d-flex transparent_background_padding ${s.vendor_dashboard__input}`}
                    key={index}
                  >
                    <label htmlFor={`videoUrl-${index}`} className='signup-input-label-special'>
                      Youtube/Vimeo video URL {index + 1}
                    </label>
                    <input
                        autocomplete="off"
                        placeholder='Youtube/Vimeo video URL'
                        style={{ width: "100%" }}
                        value={video}
                        onChange={e => {
                            const updatedVideoUrls = [...vendorDetails.videoUrls];
                            updatedVideoUrls[index] = e.target.value;
                            setVendorDetails({...vendorDetails, videoUrls: updatedVideoUrls });
                        }}
                        name={`videoUrl-${index}`}
                        type='text'
                    />
                  </div>
                ))}
              </form>
            </section>


            <button onClick={(e) => submitForm(e)} className={s.submit_button} disabled={buttonDisabled}>Save Changes</button>
        </div>
      ) : (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          {notification.visible &&
              <div className={`${s.notification} ${s[notification.type]}`}>
                  <span>{notification.message}</span>
                  <span className={s.close_button} onClick={() => setNotification({ ...notification, visible: false })}>X</span>
              </div>
            }
          <div className={s.container}>
            <h1 style={{ marginBottom: '2rem', fontSize: '2rem' }}>Your access has expired, click the link to be emailed a new access link.</h1>
            {message ? <p>{message}</p> : (
                  <div>
                      {emailField ? (
                          <div
                          style={{ flex: 1 }}
                          className={`floating_inputs_wrapper mb-0 mb-md-20 d-flex transparent_background_padding ${s.vendor_dashboard__input}`}
                        >
                          <label htmlFor={`email-access`} className='signup-input-label-special'>
                            Email
                          </label>
                          <input
                              autocomplete="off"
                              placeholder='Please enter your email address...'
                              style={{ width: "100%", backgroundColor: "#fff", maxWidth: "30rem" }}
                              value={email}
                              onChange={e => setEmail(e.target.value)}
                              name={`email-access`}
                              type='text'
                          />
                        </div>
                      ) : null}
                      <button className={s.submit_button} onClick={handleAccessRequest} disabled={accessButtonDisabled}>Request access</button>
                  </div>
              )}
            </div>
        </div>
      )}
    </main>
  );
}

export default VendorForm;
