import React, { useContext, useEffect, useState, useRef } from "react";
import { AnswersContext } from "../../../AnswersContext";
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from "react-router-dom";
import FooterNew from ".././footernew.component";
import NavbarNew from ".././navbarnew.component";
import Timeline from ".././timeline.component";
import closecircleX from "../../../styles/closecircleX"
import ScrollJump from "../../scroll-jump.component";

const getMinimumBudget = hoursSelected => {
	switch(hoursSelected) {
		case 3:
		case 4:
			return '$700'
		case 5:
			return '$900'
		case 6:
		case 7:
		case 8:
			return '$1,000'
		case 9:
		case 10:
			return '$1,500'
		default:
			return '$700'
	}
}

const VideographerNewQuiz = () => {

	const { toggleBoolean, toggleArraySelection, quizState, hoursSelected, setValue, setBooleanFalse } = useContext(AnswersContext);


	const [ nextStep, setNextStep ] = useState(false)
	const [ openPartsDay, setOpenPartsDay ] = useState(false) //bool
	const [ partDay, setPartDay ] = useState() //string
	const [ displayPartDay, setDisplaySetDay ] = useState() //string
	const [ openMinBudget, setOpenMinBudget ] = useState(false) //bool
	const [ mobileOpenMinBudget, setMobileOpenMinBudget ] = useState(false) //bool
	const [ minBudget, setMinBudget ] = useState() //num
	const [ openMaxBudget, setOpenMaxBudget ] = useState(false) //bool
	const [ mobileOpenMaxBudget, setMobileOpenMaxBudget ] = useState(false) //bool
	const [ maxBudget, setMaxBudget ] = useState() //num
	const [ openNumHours, setOpenNumHours ] = useState(false) //bool
	const [ mobileOpenNumHours, setMobileOpenNumHours ] = useState(false) //bool
	const [ numHours, setNumHours ] = useState() //num
	const [ estimatedBudget, setEstimatedBudget] = useState(false)

	const isMobile = useMediaQuery({ query: '(max-width: 550px)' });
	const hoursDropdownRef = useRef(null);
	const minDropdownRef = useRef(null);
	const maxDropdownRef = useRef(null);
	const history = useNavigate();


	const handleClickOutside = (event) => {
    if (hoursDropdownRef.current && !hoursDropdownRef.current.contains(event.target)) {
      setOpenNumHours(false);
    }
		if (minDropdownRef.current && !minDropdownRef.current.contains(event.target)) {
      setOpenMinBudget(false);
    }
		if (maxDropdownRef.current && !maxDropdownRef.current.contains(event.target)) {
      setOpenMaxBudget(false);
    }
  };


	// const confirmPartsOfDay = () => {
	// 	setOpenPartsDay(false)
	// 	setDisplaySetDay(partDay)
	// }


	const renderMinBudgetDropdown = (budgetOptions) => {

		return (
			<span className="mobile:hidden absolute w-full max-h-48 flex flex-col px-1 py-2 bg-white rounded-xl text-center shadow overflow-auto">
				{budgetOptions.map((minOption) => {
					const minOptionValue = parseFloat(minOption.replace('$', ''));

					if (quizState.videographerMinMax[1]) {
						const maxBudgetValue = parseFloat(quizState.videographerMinMax[1].replace('$', ''));
						if (maxBudgetValue > minOptionValue) {
							return (
								<span
									key={minOption}
									className="hover:bg-gray-100 px-2 py-1.5 rounded-lg cursor-pointer"
									onClick={() => {
										quizState.videographerMinMax[0] = minOption;
										setOpenMinBudget(false);
									}}
								>
									{minOption}
								</span>
							);
						}
					} else {
						return (
							<span
								 key={minOption}
								 className="hover:bg-gray-100 px-2 py-1.5 rounded-lg cursor-pointer"
								 onClick={() => {
									 quizState.videographerMinMax[0] = minOption;
									 setOpenMinBudget(false);
								 }}
							 >
								 {minOption}
						 </span>
						 );
					}

				})}
			</span>
		)
	}


	const renderMaxBudgetDropdown = (budgetOptions) => {
		return (
			<span className="mobile:hidden absolute w-full max-h-48 flex flex-col px-1 py-2 bg-white rounded-xl text-center shadow overflow-auto">
				{budgetOptions.map((maxOption) => {
					let maxOptionValue = (maxOption.replace('$', ''));
					if (maxOptionValue.includes('+')) {
						maxOptionValue = parseFloat(maxOptionValue.replace('+', '')) + 1
					} else {
						maxOptionValue = parseFloat(maxOptionValue)
					}

					if (quizState.videographerMinMax[0]) {
						const minBudgetValue = parseFloat(quizState.videographerMinMax[0].replace('$', ''));

						if (maxOptionValue > minBudgetValue) {
							return (
								<span
									key={maxOption}
									className="hover:bg-gray-100 px-2 py-1.5 rounded-lg cursor-pointer"
									onClick={() => {
										quizState.videographerMinMax[1] = maxOption;
										setOpenMaxBudget(false);
									}}
								>
									{maxOption}
								</span>
							);
						}
						return null
					}

					return (
						<span
							key={maxOption}
							className="hover:bg-gray-100 px-2 py-1.5 rounded-lg cursor-pointer"
							onClick={() => {
								quizState.videographerMinMax[1] = maxOption;
								setOpenMaxBudget(false);
							}}
						>
							{maxOption}
						</span>
					);
				})}
			</span>
		);
	}


	const renderMobileMinBudgetDropdown = (budgetOptions) => {
		return (
			<span className="flex flex-wrap justify-center">
				{budgetOptions.map((minOption) => {
					const minOptionValue = parseFloat(minOption.replace('$', ''));
					if (quizState.videographerMinMax[1]) {
						const maxBudgetValue = parseFloat(quizState.videographerMinMax[1].replace('$', ''));
						if (maxBudgetValue > minOptionValue) {
							return (
								<button
									key={minOption}
									className="hover:bg-gray-100 w-[140px] py-2 mr-2 my-1.5 border rounded-xl"
									onClick={() => {
										quizState.videographerMinMax[0] = minOption;
										setMobileOpenMinBudget(false);
									}}
								>
									{minOption}
								</button>
							);
						}
					} else {
						return (
							<button
								 key={minOption}
								 className="hover:bg-gray-100 w-[140px] py-2 mr-2 my-1.5 border rounded-xl"
								 onClick={() => {
									 quizState.videographerMinMax[0] = minOption;
									 setMobileOpenMinBudget(false);
								 }}
							 >
								 {minOption}
						 </button>
						 );
					}

				})}
			</span>
		)
	}


	const renderMobileMaxBudgetDropdown = (budgetOptions) => {
		return (
			<span className="flex flex-wrap justify-center">
				{budgetOptions.map((maxOption) => {
					let maxOptionValue = (maxOption.replace('$', ''));
					if (maxOptionValue.includes('+')) {
						maxOptionValue = parseFloat(maxOptionValue.replace('+', '')) + 1
					} else {
						maxOptionValue = parseFloat(maxOptionValue)
					}

					if (quizState.videographerMinMax[0]) {
						const minBudgetValue = parseFloat(quizState.videographerMinMax[0].replace('$', ''));

						if (maxOptionValue > minBudgetValue) {
							return (
								<button
									key={maxOption}
								  className="hover:bg-gray-100 w-[140px] py-2 mr-2 my-1.5 border rounded-xl"
									onClick={() => {
										quizState.videographerMinMax[1] = maxOption;
										setMobileOpenMaxBudget(false);
									}}
								>
									{maxOption}
								</button>
							);
						}
						return null
					}

					return (
						<button
							key={maxOption}
							className="hover:bg-gray-100 w-[140px] py-2 mr-2 my-1.5 border rounded-xl"
							onClick={() => {
								quizState.videographerMinMax[1] = maxOption;
								setMobileOpenMaxBudget(false);
							}}
						>
							{maxOption}
						</button>
					);
				})}
			</span>
		);
	}


	useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


	useEffect(() => {
		if (quizState.videographerMinMax[0] && quizState.videographerMinMax[1]) {
			const e = {
				target: {
					name: 'videographerEstimatedBudget',
				}
			};

			setBooleanFalse(e);
		}
	}, [quizState.videographerMinMax[0], quizState.videographerMinMax[1]])


	useEffect(() => {
		if (quizState.noBudget) {
			setValue('selectedBudgets', [])
		}
	}, [quizState.noBudget])


	useEffect(() => {
		if (quizState.selectedBudgets.length && quizState.noBudget) {
			setValue('noBudget', false)
		}
	}, [quizState.selectedBudgets])


	useEffect(() => {
		if (quizState.videographerHours && ((quizState.videographerMinMax[1] && quizState.videographerMinMax[0]) || quizState.videographerEstimatedBudget)) {
			setNextStep(true)
		} else {
			setNextStep(false)
		}
	}, [quizState.videographerHours, quizState.videographerMinMax[1], quizState.videographerMinMax[0], quizState.videographerEstimatedBudget] )


	const budgets =  [
		{
			id: 1,
			displayMinimum: getMinimumBudget(hoursSelected),
			displayMaximum: '$2,000'
		},
		{
			id: 2,
			displayMinimum: '$2,001',
			displayMaximum: '$3,000'
		},
		{
			id: 3,
			displayMinimum: '$3,001',
			displayMaximum: '$4,000'
		},
		{
			id: 4,
			displayMinimum: '$4,001',
			displayMaximum: '$6,000'
		},
		{
			id: 5,
			displayMinimum: '$6,000',
		},
	]


	return (
		<div className={`${(mobileOpenMinBudget || mobileOpenMaxBudget || mobileOpenNumHours) ? 'mobile:h-max' : ''} w-full h-screen flex flex-col items-center pb-32 bg-[#F5F7FC] font-poppins`}>
			{(mobileOpenMinBudget || mobileOpenMaxBudget || mobileOpenNumHours) && (
				<div className="hidden mobile:flex fixed inset-0 bg-gray-500 bg-opacity-50 backdrop-blur-sm z-30"/>
			)}

			<div className="w-full">
					<NavbarNew />
			</div>

			<div className={`w-[75%] max-w-[1000px] flex flex-col items-center px-3 pt-3 pb-[24px] mt-5 rounded-3xl bg-white bg-white/50 mobile:w-[90%]`}>
				{/* Status Bar */}


				<Timeline phase="preferences"/>

				{mobileOpenMinBudget && (
					<div className="hidden mobile:flex absolute w-[90%] h-max flex-col justify-center pt-[15px] pb-[30px] border bg-white rounded-xl text-base shadow overflow-auto z-50" ref={minDropdownRef}>
						<div className="w-full flex justify-end pr-3"><button onClick={() => setMobileOpenMinBudget(false)}>{closecircleX}</button></div>
						<p className="ml-4 mb-2 text-lg font-medium">Your Wedding Budget</p>
						{renderMobileMinBudgetDropdown(budgetOptions)}
					</div>
				)}


				{mobileOpenMaxBudget && (
					<div className="hidden mobile:flex absolute w-[90%] h-max flex-col justify-center pt-[15px] pb-[30px] border bg-white rounded-xl text-base shadow overflow-auto z-50" ref={maxDropdownRef}>
						<div className="w-full flex justify-end pr-3"><button onClick={() => setMobileOpenMaxBudget(false)}>{closecircleX}</button></div>
						<p className="ml-4 mb-2 text-lg font-medium">Your Wedding Budget</p>
						{renderMobileMaxBudgetDropdown(budgetOptions)}
					</div>
				)}


				{mobileOpenNumHours && (
						<div className="hidden mobile:flex absolute w-[90%] h-max flex-col justify-center pt-[15px] pb-[30px] border bg-white rounded-xl text-base shadow overflow-auto z-50" ref={hoursDropdownRef}>
							<div className="w-full flex justify-end pr-3"><button onClick={() => setMobileOpenNumHours(false)}>{closecircleX}</button></div>
							<p className="ml-4 text-lg font-medium">Number of Hours</p>
							<div className="flex flex-wrap justify-center mt-3">
								{hoursOptions.map((hourOption) => (
										<button className="hover:bg-gray-100 w-[140px] py-2 mr-2 my-1.5 border rounded-xl text-sm" onClick={() => (quizState.videographerHours = hourOption, setMobileOpenNumHours(false))}>{hourOption}</button>
								))}
							</div>
						</div>
				)}

				<div className={`${(mobileOpenMinBudget || mobileOpenMaxBudget || mobileOpenNumHours) ? "mobile:hidden" : "" } w-full flex flex-col items-center pt-10 pb-6 mx-12 border-t z-10`}>

					<div className="mb-7 text-center mobile:mb-10">
						<p className="mb-7 text-3xl font-medium mobile:mb-5 mobile:text-lg">Videographer Preferences</p>
						<p className="mobile:text-[13px]">Click or tap to fill in the blanks.</p>
					</div>

					<div className="w-[70%] flex flex-wrap justify-center items-center gap-y-5 text-lg mobile:w-full mobile:mb-6 mobile:text-sm">
						<span>I'm interested in</span>

						<span
							className="px-4 mx-3 text-greenblue italic"
							style={{ borderBottom: `1px solid #0A8A95` }}
						>
							a Videographer
						</span>

						<span>for</span>

						<span className="relative" ref={hoursDropdownRef}>
							<span
								className={`${quizState.videographerHours ? 'text-greenblue' : 'text-[#A6A6A6]'} px-4 mx-2 italic cursor-pointer`}
								style={{ borderBottom: `1px solid ${quizState.videographerHours ? '#0A8A95' : '#A6A6A6'}` }}
								onClick={() => {isMobile ? setMobileOpenNumHours(!mobileOpenNumHours) : setOpenNumHours(!openNumHours)}}
							>
								{quizState.videographerHours || 'Number of Hours'}
							</span>
							{openNumHours &&
								<span className="mobile:hidden absolute w-full h-48 flex flex-col px-1 py-2 bg-white rounded-xl shadow overflow-auto z-10">
									{hoursOptions.map((hourOption) => (
										<button className="hover:bg-gray-100 px-2 py-1.5 rounded-lg" onClick={() => (quizState.videographerHours = hourOption, setOpenNumHours(false))}>{hourOption}</button>
									))}
								</span>
							}
						</span>


						<span>and my budget is between</span>


						<span className="relative" ref={minDropdownRef}>
							<span
								className={`${quizState.videographerMinMax[0] ? 'text-greenblue' : 'text-[#A6A6A6]'} px-4 mx-2 italic cursor-pointer`}
								style={{ borderBottom: `1px solid ${quizState.videographerMinMax[0] ? '#0A8A95' : '#A6A6A6'}` }}
								onClick={() => {isMobile ? setMobileOpenMinBudget(!mobileOpenMinBudget) : setOpenMinBudget(!openMinBudget)}}
							>
								{quizState.videographerMinMax[0] || 'Min'}
							</span>
							{openMinBudget && renderMinBudgetDropdown(budgetOptions)}
						</span>

						<span>and</span>

						<span className="relative" ref={maxDropdownRef}>
							<span
								className={`${quizState.videographerMinMax[1] ? 'text-greenblue' : 'text-[#A6A6A6]'} px-4 mx-2 italic cursor-pointer`}
								style={{ borderBottom: `1px solid ${quizState.videographerMinMax[1] ? '#0A8A95' : '#A6A6A6'}` }}
								onClick={() => {isMobile ? setMobileOpenMaxBudget(!mobileOpenMaxBudget) : setOpenMaxBudget(!openMaxBudget)}}
							>
								{quizState.videographerMinMax[1] || 'Max'}
							</span>
							{openMaxBudget && renderMaxBudgetDropdown(budgetOptions)}
						</span>
					</div>


				</div>

				{/* <div className="w-full flex justify-center items-center mt-2 text-center mobile:text-sm">
					<label class="checkbox-container" style={{marginRight: '8px', marginTop: '0px'}}>
						<input
							name="videographerEstimatedBudget"
							checked={quizState.videographerEstimatedBudget}
							type="checkbox"
							onClick={(e) => {toggleBoolean(e)}}
						/>
						<span class="checkmark email-checkmark" />
					</label>
					<span className="">Use typical price range based on my wedding details</span>
				</div> */}



				<div>
						<FooterNew phase={"Videographer"} complete={nextStep}/>
				</div>

			</div>

		</div>
	);
};

export default VideographerNewQuiz;



const partsOfDay = [
"All Parts of the Day",
"Getting Ready",
"Ceremony",
"Couple's Portraits",
"Reception"
]


const hoursOptions = [
'2 Hours',
'3 Hours',
'4 Hours',
'5 Hours',
'6 Hours',
'7 Hours',
'8 Hours',
'9 Hours',
'10 Hours'
]


const budgetOptions = [
'$1000',
'$1500',
'$2000',
'$2500',
'$3000',
'$3500',
'$4000',
'$5000',
'$6000',
'$7500',
'$7500+'
]