import React, { useState, useEffect } from "react"
import axios from 'axios'

export default function PreferredVendors({ vendor, photographerId }) {
    const [ preferredVendors, setPreferredVendors ] = useState(vendor.preferred_vendors ?? [{ name: '', vendorCategory: null }, { name: '', vendorCategory: null }, { name: '', vendorCategory: null }, { name: '', vendorCategory: null }, { name: '', vendorCategory: null }])
    const [ vendorTypes, setVendorTypes ] = useState([])

    useEffect(() => {
        axios.get('/api/all-vendor-types')
        .then(r => {
            setVendorTypes(r.data)
        })
    }, [])
    const updateVendorName = (e, index) => {
      const name = e.target.value
      const before = [...preferredVendors.slice(0, index)]
      const after = [...preferredVendors.slice(index + 1, preferredVendors.length)]
      let inserting = null
      inserting = { name: e.target.value, vendorType: preferredVendors[index].vendorType }
      setPreferredVendors(prev => {
        if (name || preferredVendors.length <= 5) {
          return [...before, inserting, ...after]
        }
        if (preferredVendors.length > 5) {
            return [...before, ...after]
        }
      })
    }


    const updateVendorType = (e, index) => {
        const before = [...preferredVendors.slice(0, index)]
        const after = [...preferredVendors.slice(index + 1, preferredVendors.length)]
        let inserting = null
        inserting = { name: preferredVendors[index].name, vendorType: e.target.value }
        setPreferredVendors(prev => {
            return [...before, inserting, ...after]
        })
      }
    const handleSubmit = () => {
        if (photographerId) {
            axios.post('/api/update-photographer-preferred-vendors', {preferredVendors, id: photographerId, vendorId: vendor.id})
            .then(r => window.location = window.location)

        } else {
        axios.post('/api/update-preferred-vendors', {preferredVendors, vendorId: vendor.id})
        .then(r => window.location = window.location)
        }
    }

    return (
        <div style={{maxWidth: '735px'}} className='vendor-dashboard-card'>
        <div className='vendor-dashboard-card-header'>Preferred Vendors</div>
        {preferredVendors.map((v, index) =>
            <Vendor vendorTypes={vendorTypes} updateVendorType={updateVendorType} updateVendorName={updateVendorName} vendor={v} index={index} key={index} />
        )}
        {preferredVendors.length < 20 && <button className='vendor-dashboard-button vendor-dashboard-button-secondary' style={{marginTop: '8px'}} onClick={() => setPreferredVendors(prev => [...prev, { name: '', isValid: true }])}>Add Vendor</button>}

        <div style={{marginTop: '24px'}}>
          <button onClick={handleSubmit} className='vendor-dashboard-button vendor-dashboard-button-primary vendor-dashboard-button-save'>Save Changes</button>
        </div>
      </div>
    )
}

const Vendor = ({ updateVendorName, vendor, index, vendorTypes, updateVendorType }) => {
    const [searchResults, setSearchResults ] = useState([])
    const searchBusinessName = (term) => {
        if (vendor.vendorType != 15) {
            axios.get(`/api/get-vendors/${term}`)
            .then(r => {
                setSearchResults(r.data.vendors)
            })
        } else {
            axios.get(`/api/get-photographers/${term}`)
            .then(r => {
                setSearchResults(r.data.photographers)
            })
        }
    }

    return (
        <div onClick={() => setSearchResults([])} style={{marginBottom: '8px'}}>
            <div style={{position: 'relative', display: 'flex', gap: '23px'}}>
            <input className='vendor-dashboard-text-input' style={{width: '300px'}} placeholder="Vendor Name" value={vendor.name} onChange={(e) => { searchBusinessName(e.target.value); updateVendorName(e, index); }} valueName="Business Name"/>

            {searchResults.length > 0 && <div className='search-results'>
                {searchResults.map(s => <div onClick={(e) => { e.preventDefault(); e.stopPropagation(); updateVendorName({ target: { value: s.business_name }}, index); setSearchResults([])}} key={s.id}>{s.business_name}</div>)}
            </div>}
            <select value={vendor.vendorType} className='vendor-dashboard-select' onChange={(e) => updateVendorType(e, index)} placeholder='Vendor Category'>
                <option value={null}>Vendor Category</option>
                {vendorTypes.map(v => v.name && <option key={v.id} value={v.id}>{v.name}</option>)}
            </select>
            </div>
        </div>
    )
}