import React, { useState, useEffect, useContext } from "react";
import axios from 'axios'
import closecircleX from "../../styles/closecircleX"

const Booking = ({ setOpenConfirmBooking, setBlurScreen, s, scoredSelections, saveResults, userData, userInfo, setRefreshTrigger }) => {


  const [ bookingAmount, setBookingAmount ] = useState()
  const [ isBooked, setIsBooked ] = useState(false);


  const handleCheckboxChange = (e) => {
    setIsBooked(e.target.checked);
  };


  const handleAmountChange = (e) => {
    const value = e.target.value.replace(/[^\d.]/g, "");
    const parts = value.split(".");
    if (parts.length > 2) return;
    if (parts[1] && parts[1].length > 2) return;

    setBookingAmount(value);
  };


  const saveBookingDetails = async () => {
    let bookingDetails = {
      user_id: userData.user_id,
      user_created_at: userInfo.created_at,
      user_email: userInfo.email,
      wedding_date: `${userData.month}/${userData.day}/${userData.year}`,
      booking_amount: bookingAmount,
      vendor_name: s.business_name,
      vendor_id: s.id,
      vendor_email: s.contact_email || s.email,
      booking_status: isBooked
    }

    // Save the scored results to the backend
    const savedResults = await axios.post('/api/confirm-booking', {
      bookingDetails: bookingDetails,
      userId: userData.user_id,
    });

  }

  const confirmBooking = () => {
    if (bookingAmount > 0 && isBooked) {
      const vendorIndex = scoredSelections.findIndex(vendor => vendor.id === s.id);
      if (vendorIndex !== -1) {
        const updatedScoredSelections = scoredSelections.map((vendor, index) => {
          if (index === vendorIndex) {
            return {
              ...vendor,
              bookedAmount: bookingAmount
            };
          }
          return vendor;
        });
        // saveResults(updatedScoredSelections)
      }
        setRefreshTrigger(prev => prev + 1);
        saveBookingDetails();
        setBlurScreen(false);
        setOpenConfirmBooking(null);
    }
  }


  return (
    <div className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 w-[550px] px-4 py-7 border bg-white rounded-2xl shadow-lg z-50 font-poppins mobile:w-[90%] mobile:h-[90%] mobile:overflow-auto'>
        <div className="w-full flex justify-end">
            <button onClick={() => (setOpenConfirmBooking(null), setBlurScreen(false))} className="">{closecircleX}</button>
        </div>


        <div className="w-full px-4">
          <div>
            <p className="pt-6 pb-3 text-3xl text-center font-medium mobile:text-xl">You've booked a vendor!</p>
            <p className="text-center">Help us out by sharing a few details.</p>
          </div>

          <div className="flex justify-center mt-4">
            <div className="w-[160px] h-[160px] mobile:w-[40%] h-[40%]">
                <img src={s.url || s.profile_photo_url} alt=""/>
            </div>
          </div>


          <div className="w-full flex justify-start items-center mt-10 text-center text-[#444444] mobile:text-sm mobile:mt-[20px] mobile:flex mobile:justify-normal mobile:items-none">
            <label class="checkbox-container" style={{marginRight: '4px', marginTop: '0px'}}>
              <input
                name="photographerEstimatedBudget"
                type="checkbox"
                onChange={handleCheckboxChange}
              />
              <span class="checkmark email-checkmark" />
            </label>
            <span className="mobile:text-left mobile:ml-2">I booked <span className="font-semibold">{s.business_name}</span></span>
          </div>


          <div className="mt-7 space-y-2">
            <p className="text-[#444444] text-sm">Booking Amount ($)</p>
            <div className="relative">
              <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500">$</span>
              <input
                type="text"
                placeholder=""
                className="w-full px-7 pl-7 py-2.5 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-greenblue focus:border-teal-500"
                onChange={handleAmountChange}
              />
            </div>
          </div>


          <div className="flex justify-center pt-6">
            <button onClick={() => confirmBooking()} className={`${(bookingAmount > 0 && isBooked) ? 'opacity-100' : 'opacity-60' } w-full py-3 bg-greenblue rounded-xl text-white`}>Confirm Booking Details</button>
          </div>
        </div>
    </div>
  )
}

export default Booking