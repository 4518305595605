import React, { useState, useEffect, useContext } from 'react';
import { createClient } from '@supabase/supabase-js';
import { useNavigate } from "react-router-dom";
import { AnswersContext } from "../../../AnswersContext";
import _ from 'lodash';
import NavbarNew from '.././navbarnew.component';
import Timeline from '.././timeline.component';
import Lock from "../../../styles/lock";
import axios from 'axios';

import { calculateAttributeScores, calculateBudgetScore, calculateLocationScore, calculatePreferenceScore, calculateStyleScore, calculateTagScore, calculateVenueScore, calculateRankScore } from '../../../utils/scoring';

const supabase = createClient('https://zzhiugmthrvzmeyznffd.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inp6aGl1Z210aHJ2em1leXpuZmZkIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjExNDQ4OTIsImV4cCI6MjAzNjcyMDg5Mn0.zuIK4-Uwx9moh225qtJzAzDgEYQfE6AmVuwuM3Sanow');

const SignUp = () => {
  const { quizState, matchups, setValue, scoredPhotographers, handleChangeText } = useContext(AnswersContext);
  const {
    attributes,
    firstName,
    lastName,
    budget,
    month,
    day,
    year,
    interests,
    preference,
    email,
    optionsTotal,
    moreLocations,
    gettingReadyChecked,
    gettingReadyImportance,
    ceremonyChecked,
    ceremonyImportance,
    receptionChecked,
    receptionImportance,
    portraitsChecked,
    portraitsImportance,
    canContact,
    photoStyles,
    weddingLocation,
    styleTags,
    locationTags,
    importantTags,
    userOnlyTags,
    selectedBudgets,
    hoursSelected,
    chosenVendorTypes,
    noVenue,
    privateVenue,
    selections,
    venueVendorId,
    venueName,
    wedBudget,
    guestCount,
    photographerPartsDay,
    photographerNumHours,
    photographerMinMax,
    photographerEstimatedBudget,
    videographerHours,
    videographerMinMax,
    videographerEstimatedBudget,
    floristEstimatedBudget,
    floristMinMax,
    hairMakeupChosenServices,
    hairMakeupEstimatedBudget,
    hairMakeupMinMax,
    hairMakeupNumBridesmaid,
    plannerEstimatedBudget,
    plannerMinMax,
    chosenPlannerTypes,
    djChosenServices,
    djHours,
    djMinMax,
    djEstimatedBudget,
    bakerMinMax,
    bakerEstimatedBudget
  } = quizState

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    allowContact: true,
  });
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(false)
  const [posted, setPosted] = useState(false)
  const [vendors, setVendors] = useState([])
  const [signUpError, setSignUpError] = useState()
  const history = useNavigate();


  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);


  const handleCombinedChange = (e) => {
    handleInputChange(e);
    handleChangeText(e);
  }


  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (formData.password !== formData.confirmPassword) {
      setSignUpError("Passwords don't match");
      setLoading(false);
      return;
    }


    try {
      const { data, error } = await supabase.auth.signUp({
        email: formData.email,
        password: formData.password,
        options: {
          data: {
            first_name: formData.firstName,
            last_name: formData.lastName,
          },
        },
      });

      if (error) {
        console.log(error.message)
        console.log(error?.status)
        console.log(error?.hint)
        console.log(error?.details)
        setLoading(false);
        setSignUpError(error.message);
      } else {
        localStorage.setItem('auth_token', data.session.access_token);
        localStorage.setItem('refresh_token', data.session.refresh_token);
        const userId = data.user.id;

        if (userId) {
          try {
            // Wait for the first axios.post to complete
            const weddingResponse = await axios.post('/api/save-quiz-attributes/', {
              quizState: {
                firstName, //whole wed pref
                lastName, //whole wed pref
                wedBudget, //whole wed pref
                month, //whole wed pref
                day, //whole wed pref
                year, //whole wed pref
                interests, // not in?
                preference, // fly
                email, //whole wed pref
                moreLocations, //whole wed pref
                canContact, //whole wed pref
                styleTags,
                weddingLocation, //whole wed pref
                locationTags, //unused ?
                importantTags, //unused ?
                userOnlyTags, //unused ?
                hoursSelected, //whole wed pref
                chosenVendorTypes, //wholewed
                noVenue, //wholewed
                privateVenue, //wholwed
                venueVendorId, //wholewed
                venueName, //wholewed
                guestCount
              },
              userId: userId,
            });


            // If wedding preferences saved successfully, then run the loop
            if (weddingResponse) {
              for (const vendorType of chosenVendorTypes) {
                try {
                  let lowerCaseVendor = vendorType.toLowerCase();
                  if (lowerCaseVendor === 'photographer') {
                    const photographerResponse = await axios.post('/api/save-photographer-preferences/', {
                      photoPreferences: {
                        attributes,
                        optionsTotal, // photog
                        gettingReadyChecked, // photog
                        gettingReadyImportance, // photog
                        ceremonyChecked, // photog
                        ceremonyImportance, // photog
                        receptionChecked, // photog
                        receptionImportance, // photog
                        portraitsChecked, // photog
                        portraitsImportance, // photog
                        photoStyles, // photog
                        styleTags, // photog
                        selectedBudgets, // photog
                        selections, // photog
                        photographerPartsDay,
                        photographerNumHours,
                        photographerMinMax,
                        photographerEstimatedBudget,
                        lowerCaseVendor,
                      },
                      userId: userId,
                    })
                  } else if (lowerCaseVendor === 'videographer') {
                    const vendorResponse = await axios.post('/api/save-videographer-preferences/', {
                      vendorPreferences: {
                        attributes,
                        optionsTotal, // Vendor specific
                        gettingReadyChecked, // Vendor specific
                        gettingReadyImportance, // Vendor specific
                        ceremonyChecked, // Vendor specific
                        ceremonyImportance, // Vendor specific
                        receptionChecked, // Vendor specific
                        receptionImportance, // Vendor specific
                        portraitsChecked, // Vendor specific
                        portraitsImportance, // Vendor specific
                        photoStyles, // Vendor specific
                        styleTags, // Vendor specific
                        selectedBudgets, // Vendor specific
                        selections, // Vendor specific
                        videographerHours,
                        videographerMinMax,
                        videographerEstimatedBudget,
                        lowerCaseVendor,
                      },
                      userId: userId,
                    });
                  } else if (lowerCaseVendor === 'planner/coordinator') {
                    const vendorResponse = await axios.post('/api/save-planner-preferences/', {
                        vendorPreferences: {
                          attributes,
                          optionsTotal, // Vendor specific
                          gettingReadyChecked, // Vendor specific
                          gettingReadyImportance, // Vendor specific
                          ceremonyChecked, // Vendor specific
                          ceremonyImportance, // Vendor specific
                          receptionChecked, // Vendor specific
                          receptionImportance, // Vendor specific
                          portraitsChecked, // Vendor specific
                          portraitsImportance, // Vendor specific
                          photoStyles, // Vendor specific
                          styleTags, // Vendor specific
                          selectedBudgets, // Vendor specific
                          selections, // Vendor specific
                          chosenPlannerTypes,
                          plannerMinMax,
                          plannerEstimatedBudget,
                          lowerCaseVendor,
                        },
                        userId: userId,
                      });
                  } else if (lowerCaseVendor === 'florist') {
                    const vendorResponse = await axios.post('/api/save-florist-preferences/', {
                        vendorPreferences: {
                          attributes,
                          optionsTotal, // Vendor specific
                          gettingReadyChecked, // Vendor specific
                          gettingReadyImportance, // Vendor specific
                          ceremonyChecked, // Vendor specific
                          ceremonyImportance, // Vendor specific
                          receptionChecked, // Vendor specific
                          receptionImportance, // Vendor specific
                          portraitsChecked, // Vendor specific
                          portraitsImportance, // Vendor specific
                          photoStyles, // Vendor specific
                          styleTags, // Vendor specific
                          selectedBudgets, // Vendor specific
                          selections, // Vendor specific
                          floristMinMax,
                          floristEstimatedBudget,
                          lowerCaseVendor,
                        },
                        userId: userId,
                      });
                  } else if (lowerCaseVendor === 'hair/makeup') {
                    const vendorResponse = await axios.post('/api/save-hairmakeup-preferences/', {
                        vendorPreferences: {
                          attributes,
                          optionsTotal, // Vendor specific
                          gettingReadyChecked, // Vendor specific
                          gettingReadyImportance, // Vendor specific
                          ceremonyChecked, // Vendor specific
                          ceremonyImportance, // Vendor specific
                          receptionChecked, // Vendor specific
                          receptionImportance, // Vendor specific
                          portraitsChecked, // Vendor specific
                          portraitsImportance, // Vendor specific
                          photoStyles, // Vendor specific
                          styleTags, // Vendor specific
                          selectedBudgets, // Vendor specific
                          selections, // Vendor specific,
                          hairMakeupNumBridesmaid,
                          hairMakeupChosenServices,
                          hairMakeupMinMax,
                          hairMakeupEstimatedBudget,
                          lowerCaseVendor,
                        },
                        userId: userId,
                      });
                  } else if (lowerCaseVendor === 'dj') {
                    const vendorResponse = await axios.post('/api/save-dj-preferences/', {
                        vendorPreferences: {
                          attributes,
                          optionsTotal, // Vendor specific
                          gettingReadyChecked, // Vendor specific
                          gettingReadyImportance, // Vendor specific
                          ceremonyChecked, // Vendor specific
                          ceremonyImportance, // Vendor specific
                          receptionChecked, // Vendor specific
                          receptionImportance, // Vendor specific
                          portraitsChecked, // Vendor specific
                          portraitsImportance, // Vendor specific
                          photoStyles, // Vendor specific
                          styleTags, // Vendor specific
                          selectedBudgets, // Vendor specific
                          selections, // Vendor specific
                          djChosenServices,
                          djHours,
                          djMinMax,
                          djEstimatedBudget,
                          lowerCaseVendor,
                        },
                        userId: userId,
                      });
                  } else if (lowerCaseVendor === 'bakers') {
                    const vendorResponse = await axios.post('/api/save-baker-preferences/', {
                        vendorPreferences: {
                          attributes,
                          optionsTotal, // Vendor specific
                          gettingReadyChecked, // Vendor specific
                          gettingReadyImportance, // Vendor specific
                          ceremonyChecked, // Vendor specific
                          ceremonyImportance, // Vendor specific
                          receptionChecked, // Vendor specific
                          receptionImportance, // Vendor specific
                          portraitsChecked, // Vendor specific
                          portraitsImportance, // Vendor specific
                          photoStyles, // Vendor specific
                          styleTags, // Vendor specific
                          selectedBudgets, // Vendor specific
                          selections, // Vendor specific
                          bakerMinMax,
                          bakerEstimatedBudget,
                          lowerCaseVendor,
                        },
                        userId: userId,
                      });
                  } else {
                    console.log('randomcat')
                  }
                } catch (error) {
                  console.error('Error processing vendor:', error);
                }
              }
              setTimeout(() => {
                history('/home');
              }, 1500);
            }

          } catch (error) {
            setLoading(false);
          }
        }
      }
    } catch (err) {
      setLoading(false);
      console.error('Unexpected error:', err.message);
    }
  };




  return (
    <div className="w-full min-h-screen flex flex-col items-center pb-32 bg-[#F5F7FC] font-poppins">
      <div className="w-full">
        <NavbarNew />
      </div>

      <div className="w-[75%] max-w-[1000px] flex flex-col items-center px-3 pt-3 pb-0 mt-5 rounded-3xl bg-white mobile:w-[90%]">
        {/* Status Bar */}
        <Timeline phase="signup" />

        {/* Create Account Form */}
        <div className="w-full max-w-2xl mx-auto py-10 mobile:mt-5">
          <div className="px-16 mobile:px-4">
            <h2 className="mb-3 text-2xl font-medium text-center mobile:text-xl">Create Your Account</h2>
            <p className="text-center text-gray-600 mb-8 mobile:text-[15px]">
              Make a Wedmatch account to save and view your matches!
            </p>

            <form onSubmit={handleSubmit} className="space-y-4 mobile:text-sm">
              <div className="flex space-x-4">
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  className="w-1/2 pl-3 pr-2 py-[14px] border rounded-md"
                />
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  className="w-1/2 pl-3 pr-2 py-[14px] border rounded-md"
                />
              </div>
              <input
                type="email"
                name="email"
                placeholder="Email Address"
                value={formData.email}
                onChange={handleCombinedChange}
                className="w-full pl-3 pr-2 py-[14px] border rounded-md"
              />
              <input
                type="password"
                name="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleInputChange}
                className="w-full pl-3 pr-2 py-[14px] border rounded-md"
              />
              <input
                type="password"
                name="confirmPassword"
                placeholder="Confirm Password"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                className="w-full pl-3 pr-2 py-[14px] border rounded-md"
              />

              <div className="flex items-center">
                <label className="checkbox-container" style={{marginRight: '10px', marginTop: '0px'}}>
                  <input
                    type="checkbox"
                    name="allowContact"
                    checked={formData.allowContact}
                    onChange={handleInputChange}
                  />
                  <span className="checkmark email-checkmark" />
                </label>
                <span className="text-sm text-gray-700 mobile:ml-[8px] mobile:text-[12px]">
                  Allow my matched vendors to contact me with pricing and availability
                </span>
              </div>

              <div className="flex items-center space-x-2 p-3 bg-gray-100 rounded-md">
                <div size={20}> {Lock} </div>
                <p className="text-[11px] text-gray-600">
                  We respect your privacy. We'll never share your information without your permission.
                </p>
              </div>

              {signUpError && <div className="text-red-500">{signUpError}</div>}

              <div className="flex justify-center">
                {loading ?
                <div
                className="bg-greenblue px-[36px] py-3 mt-[20px] rounded-full text-white mobile:text-xs mobile:px-4 flex items-center"
                >
                  <svg className="animate-spin h-5 w-5 mr-3 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Creating Account...
                </div>
                :
                <button
                type="submit"
                className={`bg-greenblue px-[36px] py-3 mt-[20px] rounded-full text-white mobile:text-xs mobile:px-4`}
                >
                  Create Account and View Matches
                </button>
                }
              </div>
            </form>


            {/* <p className="text-center text-sm text-greenblue mt-4 hover:underline cursor-pointer mobile:text-xs">
              I already have a Wedmatch account
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;








const vendorRemap = {
  "Planner": "13",
  "Hair and Makeup": "7",
  "Florists": "3",
  "Videographers": "6",
  "DJs": "5",
  "Cake Bakers": "2"
}




const vendorTypeMap = {
  'details': {
      components: [
          {
              property: 'weddingDate',
              alternateProperty: 'dateNotDecided',
              jsx: <span>I'm getting married on </span>,
              displayModal: 'WeddingDate',
              label: 'select date',
              value: quizState => {
                  if (quizState.dateNotDecided) return 'no date yet'
                  if (typeof quizState.weddingDate !== 'object') return quizState.weddingDate
                  return `${quizState.weddingDate.getMonth() + 1}/${quizState.weddingDate.getDate()}/${quizState.weddingDate.getFullYear()}`
              }
          },
          {
              property: 'weddingLocation',
              jsx: <span> in </span>,
              displayModal: 'WeddingLocation',
              label: 'select location',
              value: (quizState, locations) => {
                  if (quizState.weddingLocation) {
                      return `${locations.find(l => l.id == quizState.weddingLocation)?.name} ${quizState.venueName ? `at ${quizState.venueName}` : ''}`
                  }
              }
          },
          {
              property: 'overallBudgetMin',
              jsx: <span>&nbsp;with a total wedding budget between </span>,
              label: 'min',
              small: true,
              type: 'money',
          },
          {
              property: 'overallBudgetMax',
              jsx: <span> and </span>,
              label: 'max',
              small: true,
              type: 'money',
          },
          {
              property: 'minGuests',
              jsx: <span>. I plan to have between </span>,
              label: 'min',
              type: 'number',
              small: true,
          },
          {
              property: 'maxGuests',
              jsx: <span> and </span>,
              label: 'max',
              type: 'number',
              small: true,
          },
          {
              property: 'chosenTags',
              jsx: ' guests. Some words that describe my wedding are ',
              label: 'select tags',
              optionsProperty: 'tags',
              header: 'Tags',
              subheader: `We'll use your choices here to show you a custom set of real wedding photos that best match your big day.`,
              question: 'Select any of these words that describe this wedding.',
              displayModal: 'Options',
              value: (quizState, locations, tags) => quizState.chosenTags?.map(t => tags.find(ta => ta.id == t)?.name).join(', ')
          },
          {
              jsx: '.'
          }

      ],
  },
  13: { // Planner
      rootProperty: 'planner',
      pricingScores: [

          {
              label: 'Budget (dayOf)',
              vendorMin: 'dayOfMinimum',
              vendorMax: 'dayOfMaximum',
              userMin: 'planner.budgetMin',
              userMax: 'planner.budgetMax',
              condition: (quizState) => quizState.planner?.services === "Day-of Coordination Only"
          },
          {
              label: 'Budget (partialPlanning)',
              vendorMin: 'partialPlanningMinimum',
              vendorMax: 'partialPlanningMaximum',
              userMin: 'planner.budgetMin',
              userMax: 'planner.budgetMax',
              condition: (quizState) => quizState.planner?.services === "Partial planning"
          },
          {
              label: 'Budget (fullService)',
              vendorMin: 'fullServiceMinimum',
              vendorMax: 'fullServiceMaximum',
              userMin: 'planner.budgetMin',
              userMax: 'planner.budgetMax',
              condition: (quizState) => quizState.planner?.services === "Full service"
          },
          {
              label: 'Budget (overall)',
              vendorMin: 'overallBudgetMinimum',
              vendorMax: 'overallBudgetMaximum',
              userMin: 'overallBudgetMin',
              userMax: 'overallBudgetMax',
          },
      ],
      components: [
          {
              jsx: <span>I'm looking for</span>,
              label: 'select services',
              property: 'planner.services',
              selectOne: true,
              options: ['Full service', 'Day-of Coordination Only', 'Partial planning'],
              header: 'Wedding Planning Services',
              subheader: 'We collect detailed pricing from planners to make sure they match your budget.',
              question: 'Which planning services are you interested in?',
              displayModal: 'Options',
              value: (quizState) => `${quizState.planner.services} planning`
          },
          {
              jsx: <span>. My budget for a wedding planner is between </span>,
              property: 'planner.budgetMin',
              label: 'min',
              small: true,
              type: 'money',
          },
          {
              jsx: <span> and </span>,
              property: 'planner.budgetMax',
              label: 'max',
              small: true,
              type: 'money',
          }
      ],
      servicesOfferedMap: {
          'Day-of Coordination Only': 'dayOfCoordinationDisabled',
          'Full service': 'fullServiceDisabled',
          'Partial planning': 'partialPlanningDisabled'
      }

  },
  3: { // Florist
      pricingScores: [
          {
              label: 'Budget',
              vendorMin: 'averageSpendMinimum',
              vendorMax: 'averageSpendMaximum',
              userMin: 'florist.budgetMin',
              userMax: 'florist.budgetMax',
          },

      ],
      components: [
          {
              jsx: <span>I'm looking for </span>,
              label: 'select flowers',
              property: 'florist.services',
              options: ['Bridal Bouquet', 'Bridesmaids Bouquets', 'Boutonnieres', 'Ceremony Decor', 'Centerpieces'],
              header: 'Florist Services',
              subheader: 'We collect detailed pricing from florists to make sure they match your budget.',
              question: 'Which florist services are you interested in?',
              displayModal: 'Options',
              value: (quizState) => quizState.florist?.services?.join(', ')
          },
          {
              jsx: <span>. My budget for flowers is between </span>,
              property: 'florist.budgetMin',
              label: 'min',
              small: true,
              type: 'money',
          },
          {
              jsx: <span> and </span>,
              property: 'florist.budgetMax',
              label: 'max',
              small: true,
              type: 'money',
          }
      ],
  },
  2: { // Cake
      pricingScores: [
          {
              label: 'Budget',
              vendorMin: 'perPersonMinimum',
              vendorMax: 'perPersonMaximum',
              userMin: (quizState) => {
                  return cleanNumber(quizState.baker.budgetMin) / ((cleanNumber(quizState.minGuests) + cleanNumber(quizState.maxGuests))/2)
              },
              userMax: (quizState) => {
                  return cleanNumber(quizState.baker.budgetMax) / ((cleanNumber(quizState.minGuests) + cleanNumber(quizState.maxGuests))/2)
              }
          },

      ],
      components: [
          {
              jsx: <span>I'm looking for</span>,
              label: 'select desserts',
              property: 'baker.services',
              options: ['Cake', 'Cookies and Macarons', 'Cupcakes', 'Mini Desserts', 'Pies', 'Donuts', 'Pastries', 'Candies'],
              header: 'Baker Services',
              subheader: 'We collect detailed pricing from bakers to make sure they match your budget.',
              question: 'Which baker services are you interested in?',
              displayModal: 'Options',
              value: (quizState) => quizState.baker?.services?.join(', ')
          },
          {
              jsx: <span>. My budget for desserts is between </span>,
              property: 'baker.budgetMin',
              label: 'min',
              small: true,
              type: 'money',
          },
          {
              jsx: <span> and </span>,
              property: 'baker.budgetMax',
              label: 'max',
              small: true,
              type: 'money',
          }
      ],

  },
  5: { // DJ
      pricingScores: [
          {
              label: 'Budget',
              vendorMin: (quizState) => {
                  const serviceMap = {
                      'Reception Coverage': 'average',
                      'Ceremony Coverage': 'ceremonyAverage',
                      'Photo-booth': 'photobooth',
                      'Up-lighting': 'uplighting'
                  }
                  if (quizState.dj.services.length === 1) {
                      if(quizState.dj.services[0] === 'Reception Coverage') {
                          return 'average'
                      }
                      if (quizState.dj.services[0] === 'Ceremony Coverage') {
                          return 'ceremonyAverage'
                      }
                  }
                  if (quizState.dj.services.length === 2) {
                      if (quizState.dj.services.includes('Reception Coverage') && quizState.dj.services.includes('Ceremony Coverage')) {
                          return 'receptionCeremonyAverage'
                      }

                  }
                  if (quizState.dj.services.includes('Reception Coverage') && quizState.dj.services.includes('Ceremony Coverage')) {
                      return ['receptionCeremonyAverage', ...quizState.dj.services.filter(s => s !== 'Reception Coverage' && s !== 'Ceremony Coverage').map(s => serviceMap[s])]
                  }
                  return quizState.dj.services.map(s => serviceMap[s])
              },
              vendorMax: (quizState) => {
                  const serviceMap = {
                      'Reception Coverage': 'average',
                      'Ceremony Coverage': 'ceremonyAverage',
                      'Photo-booth': 'photobooth',
                      'Up-lighting': 'uplighting'
                  }
                  if (quizState.dj.services.length === 1) {
                      if(quizState.dj.services[0] === 'Reception Coverage') {
                          return 'average'
                      }
                      if (quizState.dj.services[0] === 'Ceremony Coverage') {
                          return 'ceremonyAverage'
                      }
                  }
                  if (quizState.dj.services.length === 2) {
                      if (quizState.dj.services.includes('Reception Coverage') && quizState.dj.services.includes('Ceremony Coverage')) {
                          return 'receptionCeremonyAverage'
                      }

                  }
                  if (quizState.dj.services.includes('Reception Coverage') && quizState.dj.services.includes('Ceremony Coverage')) {
                      return ['receptionCeremonyAverage', ...quizState.dj.services.filter(s => s !== 'Reception Coverage' && s !== 'Ceremony Coverage').map(s => serviceMap[s])]
                  }
                  return quizState.dj.services.map(s => serviceMap[s])
              },
              userMin: 'dj.budgetMin',
              userMax: 'dj.budgetMax',
              exceptions: [
                  {
                      score: -100,
                      condition: {
                          vendorProperty: 'ceremonyOnlyDisabled',
                          quizCondition: quizState => quizState.dj.services.includes('Ceremony Coverage') && quizState.dj.services.length === 1
                      }
                  }
              ]
          },

      ],
      components: [
          {
              jsx: <span>I'm interested in</span>,
              label: 'select services',
              property: 'dj.services',
              options: ['Reception Coverage', 'Ceremony Coverage', 'Up-lighting', 'Photo-booth'],
              header: 'DJ Services',
              subheader: 'We collect detailed pricing from DJs to make sure they match your budget.',
              question: 'Which DJ services are you interested in?',
              displayModal: 'Options',
              messageCondition: (quizState) => quizState.dj?.services?.length && !quizState.dj?.services?.includes('Reception Coverage') && !quizState.dj?.services?.includes('Ceremony Coverage'),
              message: 'Reception and/or Ceremony coverage required',
              disableCondition: (quizState) => !quizState.dj?.services?.includes('Reception Coverage') && !quizState.dj?.services?.includes('Ceremony Coverage'),
              value: (quizState) => quizState.dj?.services?.join(', ')
          },
          {
              jsx: <span>. My budget for these services is between </span>,
              property: 'dj.budgetMin',
              label: 'min',
              small: true,
              type: 'money',
          },
          {
              jsx: <span> and </span>,
              property: 'dj.budgetMax',
              label: 'max',
              small: true,
              type: 'money',
          }
      ],
  },
  15: { // Photographer
      components: [
          {
              jsx: <span>I'm interested in</span>,
              label: 'select services',
              property: 'photographer.services',
              options: ['Getting Ready', 'Ceremony', 'Couples Portraits', 'Wedding Party',  'Reception', 'Formal Dance'],
              header: 'Photography Coverage',
              subheader: 'We collect detailed pricing from photographers to make sure they match your budget.',
              question: 'Which photographer services are you interested in?',
              displayModal: 'Options',
              value: (quizState) => quizState.photographer?.services?.join(', ')
          },
          {
              jsx: <span>. My budget for these services is between </span>,
              property: 'photographer.budgetMin',
              label: 'min',
              small: true,
              type: 'money',
          },
          {
              jsx: <span> and </span>,
              property: 'photographer.budgetMax',
              label: 'max',
              small: true,
              type: 'money',
          },
          {
              property: 'photographer.minHours',
              jsx: <span>. I'd like between </span>,
              label: 'min',
              type: 'number',
              small: true,
          },
          {
              property: 'photographer.maxHours',
              jsx: <span> and </span>,
              label: 'max',
              type: 'number',
              small: true,
          },
          {
              jsx: <span> hours of coverage and I most care about my photographer being </span>,
              label: 'select attributes',
              property: 'photographer.personality',
              // options: attributeOptions,
              header: 'Photographer Personality',
              subheader: 'What is most important to you in a photographer?',
              question: 'What is most important to you in a photographer?',
              displayModal: 'Options',
              value: (quizState) => quizState.photographer.personality.join(', ')
          },
          {
              property: 'photoStyles',
              alternateProperty: 'styleNotImportant',
              jsx: <span> My favorite photographer editing style is </span>,
              displayModal: 'PhotoStyles',
              label: 'Photo Style',
              value: quizState => {
                  if (!quizState.photoStyles.find(s => s.rank)) return 'no style yet'
                  return quizState.photoStyles.find(s => s.rank === 1).description
              }
          },
      ],
  },
  6: { // Videographer
      pricingScores: [
          {
              label: 'Budget (From coverageAverage)',
              vendorMin: 'coverageAverageMinimum',
              vendorMax: 'coverageAverageMaximum',
              userMin: 'videographer.budgetMin',
              userMax: 'videographer.budgetMax',
              condition: (quizState) => {
                 // return quizState.videographer?.services?.includes('Ceremony Coverage') || quizState.videographer?.services?.includes('Reception Coverage') || quizState.videographer?.services?.includes('Drone Coverage') || quizState?.videographer?.services?.includes('Highlight Film')
                 return true
              }
          },
          {
              label: 'Budget (From averageSpend)',
              vendorMin: 'averageSpendMinimum',
              vendorMax: 'averageSpendMaximum',
              userMin: 'videographer.budgetMin',
              userMax: 'videographer.budgetMax',
              condition: (quizState) => {
                  // return !(quizState.videographer?.services?.includes('Ceremony Coverage') || quizState.videographer?.services?.includes('Reception Coverage') || quizState.videographer?.services?.includes('Drone Coverage') || quizState?.videographer?.services?.includes('Highlight Film'))
                  return true
              }
          },

      ],
      components: [
          {
              jsx: <span>I'm interested in</span>,
              label: 'select services',
              property: 'videographer.services',
              options: ['Reception Coverage', 'Ceremony Coverage', 'Drone Coverage', 'Highlight Film', 'Full-length Film'],
              header: 'Videography Coverage',
              subheader: 'We collect detailed pricing from videographers to make sure they match your budget.',
              question: 'Which videographer services are you interested in?',
              displayModal: 'Options',
              value: (quizState) => quizState.videographer?.services?.join(', ')
          },
          {
              jsx: <span>. My budget for video is between </span>,
              property: 'videographer.budgetMin',
              label: 'min',
              small: true,
              type: 'money',
          },
          {
              jsx: <span> and </span>,
              property: 'videographer.budgetMax',
              label: 'max',
              small: true,
              type: 'money',
          },
      ],
  },
  7: { // Hair/makeup
      /*

          'minimumTotal': "5000",
          'bridalHairMinimum': "500",
          "bridalHairMaximum": "6000",
          'bridalMakeupMinimum': "500",
          "bridalMakeupMaximum": "6000",
          'bridesmaidHairMinimum': "500",
          "bridesmaidHairMaximum": "6000",
          'bridesmaidMakeupMinimum': "500",
          "bridesmaidMakeupMaximum": "6000",
          "minimumDisabled": false,
          "bridalHairDisabled": false,
          "bridalMakeupDisabled": false,
          "bridesmaidHairDisabled": false,
          "bridesmaidMakeupDisabled": false,
      */
      absoluteMin: 'minimumTotal',
      pricingScores: [
          {
              label: 'Budget',
              vendorMin: (quizState) => {
                  const properties = []
                  const services = quizState.hair?.services
                  if (services) {
                      if (services.includes('Bridal Hair')) {
                          properties.push('bridalHairMinimum')
                      }
                      if (services.includes('Bridal Makeup')) {
                          properties.push('bridalMakeupMinimum')
                      }
                      if (services.includes('Bridesmaids Makeup')) {
                          Array.from(Array(quizState.hair.bridesmaidsMakeup ? parseInt(quizState.hair.bridesmaidsMakeup) : 0)).forEach(a => {
                              properties.push('bridesmaidMakeupMinimum')
                          })
                      }
                      if (services.includes('Bridesmaids Hair')) {
                          Array.from(Array(quizState.hair.bridesmaidsHair ? parseInt(quizState.hair.bridesmaidsHair) : 0)).forEach(a => {
                              properties.push('bridesmaidHairMinimum')
                          })
                      }
                  }
                  return properties
              },
              vendorMax: (quizState) => {
                  const properties = []
                  const services = quizState.hair?.services
                  if (services) {
                      if (services.includes('Bridal Hair')) {
                          properties.push('bridalHairMaximum')
                      }
                      if (services.includes('Bridal Makeup')) {
                          properties.push('bridalMakeupMaximum')
                      }
                      if (services.includes('Bridesmaids Makeup')) {
                          Array.from(Array(quizState.hair.bridesmaidsMakeup ? parseInt(quizState.hair.bridesmaidsMakeup) : 0)).forEach(a => {
                              properties.push('bridesmaidMakeupMaximum')
                          })
                      }
                      if (services.includes('Bridesmaids Hair')) {
                          Array.from(Array(quizState.hair.bridesmaidsHair ? parseInt(quizState.hair.bridesmaidsHair) : 0)).forEach(a => {
                              properties.push('bridesmaidHairMaximum')
                          })
                      }
                  }
                  return properties
              },
              userMin: 'hair.budgetMin',
              userMax: 'hair.budgetMax',
              exceptions: [
                  {
                      score: -100,
                      condition: {
                          vendorProperty: 'bridalHairDisabled',
                          quizCondition: quizState => quizState.hair?.services.includes('Bridal Hair')
                      },
                  },
                  {
                      score: -100,
                      condition: {
                          vendorProperty: 'bridalMakeupDisabled',
                          quizCondition: quizState => quizState.hair?.services.includes('Bridal Makeup')
                      }
                  },
                  {
                      score: -100,
                      condition: {
                          vendorProperty: 'bridesmaidHairDisabled',
                          quizCondition: quizState => quizState.hair?.services.includes('Bridesmaid Hair')
                      },
                  },
                  {
                      score: -100,
                      condition: {
                          vendorProperty: 'bridesmaidMakeupDisabled',
                          quizCondition: quizState => quizState.hair?.services.includes('Bridesmaid Makeup')
                      },
                  }
              ]
          },

      ],
      components: [
          {
              jsx: <span>I'm interested in</span>,
              label: 'select services',
              property: 'hair.services',
              options: ['Bridal Hair', 'Bridal Makeup', 'Bridesmaids Hair', 'Bridesmaids Makeup', 'More'],
              header: 'Hair and Makeup',
              subheader: 'We collect detailed pricing from hair and makeup vendors to make sure they match your budget.',
              question: 'Which hair and makeup services are you interested in?',
              displayModal: 'HairAndMakeupOptions',
              value: (quizState) => quizState.hair?.services?.join(', ')
          },
          {
              jsx: <span>. My budget for these services is between </span>,
              property: 'hair.budgetMin',
              label: 'min',
              small: true,
              type: 'money',
          },
          {
              jsx: <span> and </span>,
              property: 'hair.budgetMax',
              label: 'max',
              small: true,
              type: 'money',
          },
      ],
  },
}




const getMinMaxScore = (vMin, vMax, uMin, uMax) => {
  console.log(vMin, vMax)
  if (uMin && uMax) {
      const userMin = cleanNumber(uMin)
      const userMax = cleanNumber(uMax)
      const vendorMin = parseInt(vMin)
      const vendorMax = parseInt(vMax)
      console.log(userMin, userMax)
      console.log(vendorMin, vendorMax)
      if (vendorMin >= userMin && vendorMax <= userMax) {
          return 100
      }
      if (userMin > vendorMax && userMax > vendorMax) {
          if (userMin <= vendorMax * 1.5) {
              return 70
          }
          return 30
      }
      if (userMin >= vendorMin && userMax <= vendorMax) {
          return 70
      }
      if (userMax >= vendorMin && userMax <= vendorMax && userMin < vendorMin) {
          return 60
      }
      if (userMax > vendorMin * .5) {
          return 30
      }
      return -100
  }
  return -100
}





const cleanNumber = number => {
  if (typeof number === 'number') {
      return number
  }
  if (number || number === 0) {
      return parseInt(number.replace('$', '').replace(/,/g, ''))
  } return 0
}




const vendorsTestData = [
  {
      business_name: 'Test Videographer',
      vendor_type: 6,
      pricing_properties: {
          "offSeasonMaximum": "5000",
          "offSeasonMinimum": "3500",
          "averageSpendMaximum": "5500",
          "averageSpendMinimum": "3900",
          "coverageAverageMaximum": "4500",
          "coverageAverageMinimum": "3500"
      }
  },
  {
      business_name: 'Test Planner',
      vendor_type: 13,
      pricing_properties: {
          "dayOfMinimum": "3500",
          "dayOfMaximum": "5000",
          "partialPlanningMinimum": "500",
          "partialPlanningMaximum": "3900",
          "fullServiceMinimum": "3500",
          "fullServiceMaximum": "6000",
          "overallBudgetMinimum": "10000",
          "overallBudgetMaximum": "20000",
          "dayOfCoordinationDisabled": false,
          "partialPlanningDisabled": true,
          "fullServiceDisabled": false
      }
  },
  {
      business_name: 'Test Florist',
      vendor_type: 3,
      pricing_properties: {
          "averageSpendMinimum": "3500",
          "averageSpendMaximum": "5000",
      }
  },
  {
      business_name: 'Test Baker',
      vendor_type: 2,
      pricing_properties: {
          'perPersonMinimum': "5",
          'perPersonMaximum': "10",
      }
  },
  {
      business_name: 'Test DJ',
      vendor_type: 5,
      pricing_properties: {

          'average': "5000",
          'extraHourAverage': "500",
          "ceremonyOnlyDisabled": true,
          "receptionCeremonyAverage": "6000",
          "uplightingDisabled": false,
          "photoboothDisabled": false,
          "photobooth": "500",
          "ceremonyAverage": "3000",
          "uplighting": "600",
      }
  },
  {
      business_name: 'Test Makeup',
      vendor_type: 7,
      pricing_properties: {
          'minimumTotal': "5000",
          'bridalHairMinimum': "500",
          "bridalHairMaximum": "6000",
          'bridalMakeupMinimum': "500",
          "bridalMakeupMaximum": "6000",
          'bridesmaidHairMinimum': "500",
          "bridesmaidHairMaximum": "6000",
          'bridesmaidMakeupMinimum': "500",
          "bridesmaidMakeupMaximum": "6000",
          "minimumDisabled": false,
          "bridalHairDisabled": false,
          "bridalMakeupDisabled": false,
          "bridesmaidHairDisabled": false,
          "bridesmaidMakeupDisabled": false,
      }
  }
]