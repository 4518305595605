import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NotificationPopup from './AdminDashboard/notify-vendor.component'

const SelectBudget = () => {
    const [results, setResults] = useState([]);
    const [enrichedData, setEnrichedData] = useState({});
    const [userInfo, setUserInfo] = useState([])
    const [styleTags, setStyleTags] = useState()
    const [userProfiles, setUserProfiles] = useState()
    const [loading, setLoading] = useState(true);
    const [bookedVendors, setBookedVendors] = useState()
    const [openNotification, setOpenNotification] = useState(null)
    const [sortConfig, setSortConfig] = useState({
        key: null,
        direction: 'ascending'
    });



    //fetch details --------------------------------------

    const fetchBookedVendors = async () => {
        try {
          const response = await axios.get('/api/results-get-booked-vendors')
          setBookedVendors(response.data)
        } catch (error) {
          console.log(error)
        }
    }


    const fetchUserDetails = async (userIds) => {
        try {
            setLoading(true);
            const response = await axios.get('/api/results-page-get-vendor-attributes');
            const enrichedDataMap = {};
            response.data.forEach(vendorData => {
                if (vendorData.user_id) {
                    if (!enrichedDataMap[vendorData.user_id]) {
                        enrichedDataMap[vendorData.user_id] = [];
                    }
                    enrichedDataMap[vendorData.user_id].push(vendorData);
                }
            });

            setEnrichedData(enrichedDataMap);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching vendor attributes:", error);
            setLoading(false);
        }
    };


    const fetchUserProfiles = async () => {
        try {
            const response = await axios.get('/api/results-get-user-profiles')
            setUserProfiles(response.data.data)
          } catch (error) {
            console.log(error)
          }
    }


    const notificationCategory = (status) => {

        if (status === true) {
            return (
                <div className="px-3 bg-green-400">
                    Sent Emails
                </div>
            )
        } else if (status === false) {
            return (
                <div className="px-3 bg-red-400">
                    Dont send emails
                </div>
            )
        } else {
            return (
                <button className="px-3 bg-yellow-400">
                    Select
                </button>
            )
        }
    }


    const sortData = (key) => {
        let direction = 'ascending';

        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }

        const sortedData = [...results].sort((a, b) => {
            // Handle sorting for enriched data fields
            if (key.startsWith('enriched_')) {
                const enrichedKey = key.replace('enriched_', '');
                const aValue = enrichedData[a.user_id]?.[enrichedKey];
                const bValue = enrichedData[b.user_id]?.[enrichedKey];

                if (aValue === null) return 1;
                if (bValue === null) return -1;

                if (typeof aValue === 'number' && typeof bValue === 'number') {
                    return direction === 'ascending' ? aValue - bValue : bValue - aValue;
                }

                const aString = String(aValue || '').toLowerCase();
                const bString = String(bValue || '').toLowerCase();

                return direction === 'ascending'
                    ? aString.localeCompare(bString)
                    : bString.localeCompare(aString);
            }

            // Original sorting logic for base fields
            if (a[key] === null) return 1;
            if (b[key] === null) return -1;

            if (typeof a[key] === 'number' && typeof b[key] === 'number') {
                return direction === 'ascending' ? a[key] - b[key] : b[key] - a[key];
            }

            const aValue = String(a[key]).toLowerCase();
            const bValue = String(b[key]).toLowerCase();

            if (aValue < bValue) return direction === 'ascending' ? -1 : 1;
            if (aValue > bValue) return direction === 'ascending' ? 1 : -1;
            return 0;
        });

        setResults(sortedData);
        setSortConfig({ key, direction });
    };



    const SortableHeader = ({ column, label, color }) => (
        <th
            className={`border border-gray-300 p-2 ${color} cursor-pointer hover:bg-gray-200 select-none whitespace-nowrap`}
            onClick={() => sortData(column)}
        >
            <div className="flex items-center justify-between">
                {label}
                {sortConfig.key === column && (
                    <span className="ml-1 text-xs">
                        {sortConfig.direction === 'ascending' ? '↑' : '↓'}
                    </span>
                )}
            </div>
        </th>
    );


    const FilteredCategories = ({ userId, enrichedData, categories }) => {
        return Object.keys(categories).map((category, index) => {
            const vendorData = enrichedData[userId];
            const priceRangeKey = `photographer_min_max`;
            const priceRange = vendorData?.[priceRangeKey];

            return (
                <React.Fragment key={`${category}-${index}`}>
                    {(() => {
                        const matchedVendorData = vendorData?.find(
                            data => data.vendor_category.toLowerCase() === category.toLowerCase()
                        );

                        return (
                            <>

                                <td className="border border-gray-300 p-2 whitespace-nowrap">{(<div>{matchedVendorData && matchedVendorData.vendor_category}</div>)}</td>
                                <td className="border border-gray-300 p-2 whitespace-nowrap">{(<div>{matchedVendorData && matchedVendorData.photographer_min_max.join(" - ")}</div>)}</td>
                                <td className="border border-gray-300 p-2 whitespace-nowrap">{(<div>{matchedVendorData && matchedVendorData?.photographer_num_hours}</div>)}</td>
                                <td className="border border-gray-300 p-2 whitespace-nowrap">{(<div>{matchedVendorData && matchedVendorData?.photographer_parts_day}</div>)}</td>
                                <td className="border border-gray-300 p-2 whitespace-nowrap">
                                    {matchedVendorData && (
                                        <div>
                                            {matchedVendorData.scored_results
                                                ?.sort((a, b) => b.totalScore - a.totalScore) // Sort by totalScore in descending order
                                                .slice(0, 5) // Take the top 5 results
                                                .map((result, index) => (
                                                    <p key={index}>
                                                        {result.business_name} - {result.totalScore/50} - {result.contact_email}

                                                    </p>
                                                ))}
                                        </div>
                                    )}
                                </td>

                            </>
                        );
                    })()}
                </React.Fragment>
            );

        });
    };


    const FilteredUserProfiles = ({ userId }) => {
        const matchedVendorData = userProfiles?.find(profile => profile.id === userId)
        return (
            <>
                <td className="border border-gray-300 p-2 whitespace-nowrap">{(<div>{matchedVendorData && matchedVendorData.created_at}</div>)}</td>
                <td className="border border-gray-300 p-2 whitespace-nowrap">{(<div>{matchedVendorData && matchedVendorData.updated}</div>)}</td>
                <td className="border border-gray-300 p-2 whitespace-nowrap">{(<div>{matchedVendorData && matchedVendorData.first_name}</div>)}</td>
                <td className="border border-gray-300 p-2 whitespace-nowrap">{(<div>{matchedVendorData && matchedVendorData.last_name}</div>)}</td>
            </>
        );
    }



    const findVendors = ( userId ) => {
        if (bookedVendors) {
            const vendorNames = bookedVendors
            .filter(item => item.user_id === userId)
            .map(item => item.vendor_name)
            .join(", ");

            return (
                <>
                {vendorNames}
                </>
            )
        }
    }


    const Results = () => {
        return results.map((r, index) => (
            <>
            {/* {r.user_id === 'e9cb5295-6209-4ddf-999c-f797409ce7ab' && */}
                <tr key={r.id} className="hover:bg-gray-50">
                    {/* Original columns */}
                    <td className="relative border border-gray-300 p-2 whitespace-nowrap">
                    {openNotification === r.id && (<NotificationPopup r={r} setOpenNotification={setOpenNotification} userId={r.user_id} userProfiles={userProfiles} enrichedData={enrichedData} categories={categories} styleTags={styleTags}/>)}
                        <p onClick={() => setOpenNotification(r.id)}>{notificationCategory(r.is_visible)}</p>
                    </td>
                    <td className="border border-gray-300 p-2 whitespace-nowrap"></td>
                    <td className="border border-gray-300 p-2 whitespace-nowrap">{r.id}</td>
                    <td className="border border-gray-300 p-2 whitespace-nowrap">{r.email}</td>
                    <td className="border border-gray-300 p-2 whitespace-nowrap">{r.user_id}</td>
                    <FilteredUserProfiles userId={r.user_id}/>
                    <td className="border border-gray-300 p-2 whitespace-nowrap"></td>
                    <td className="border border-gray-300 p-2 whitespace-nowrap">{r.wedding_location}</td>
                    <td className="border border-gray-300 p-2 whitespace-nowrap">{r.venue_name}</td>
                    <td className="border border-gray-300 p-2 whitespace-nowrap">{r.venue_vendor_id}</td>
                    <td className="border border-gray-300 p-2 whitespace-nowrap"></td>
                    <td className="border border-gray-300 p-2 whitespace-nowrap">{r.chosen_vendor_types.join(", ")}</td>
                    <td className="border border-gray-300 p-2 whitespace-nowrap">{findVendors(r.user_id)}</td>

                    <FilteredCategories userId={r.user_id} enrichedData={enrichedData} categories={categories}/>
                </tr>
            {/* } */}
            </>
        ));
    };


    // Fetch initial results
    useEffect(() => {
        document.title = "Results | WedMatch";

        axios.get('/auth/getuser')
        .then(r => {
            if(!r.data.admin){
                window.location="/login"
            }
        })

        const fetchInitialData = async () => {
            try {
                const result = await axios.get(`/api/get-wedding-preferences-results`);
                setResults(result.data);

                // After getting initial results, fetch additional data for each user
                const userIds = result.data.map(r => r.user_id);
                await fetchUserDetails(userIds);
            } catch (error) {
                console.error("Error fetching initial data:", error);
            }
        };


        const getTags = async () => {
            try {
                const response = await axios.get('/api/all-tags');
                setStyleTags(response.data)
            } catch (error) {
                console.error("error fetching tags")
            }
        }

        getTags()
        fetchInitialData();
        fetchBookedVendors();
        fetchUserProfiles();
    }, []);


    return (
        <div className="p-6">
            <div className="font-bold mb-4">Results from those who finished the process NOT PHOTOGRAPHERS:</div>

            <button onClick={() => fetchUserProfiles()}>test</button>


            <div className="relative">

                <div className="overflow-x-auto overflow-y-auto max-h-[80vh] min-h-[500px] rounded-lg shadow-md">
                    <table className="min-w-full bg-white border border-gray-300">
                        <thead className="sticky top-0 bg-white z-10">
                            <tr>
                                {/* Original columns */}
                                <SortableHeader column="id" color="bg-blue-100" label="Notification" />
                                <SortableHeader column="id" color="bg-green-100" label="User Identification >>>" />
                                <SortableHeader column="id" color="bg-green-100" label="Id" />
                                <SortableHeader column="email" color="bg-green-100" label="Email" />
                                <SortableHeader column="user_id" color="bg-green-100" label="User Id" />
                                <SortableHeader column="user_id" color="bg-green-100" label="Account Creation Date" />
                                <SortableHeader column="user_id" color="bg-green-100" label="Last Updated" />
                                <SortableHeader column="user_id" color="bg-green-100" label="First Name" />
                                <SortableHeader column="user_id" color="bg-green-100" label="Last Name" />
                                <SortableHeader column="id" color="bg-teal-200" label="User Info >>>" />
                                <SortableHeader column="wedding_location" color="bg-teal-200" label="Wedding Location" />
                                <SortableHeader column="venue_name" color="bg-teal-200" label="Venue Name" />
                                <SortableHeader column="venue_vendor_id" color="bg-teal-200" label="Venue Vendor Id" />
                                <SortableHeader column="venue_name" color="bg-pink-200" label="Vendors >>>" />
                                <SortableHeader column="venue_name" color="bg-pink-200" label="Vendor Categories" />
                                <SortableHeader column="venue_name" color="bg-pink-200" label="Booked Vendors" />


                                {Object.keys(categories).map((category, index) => (
                                    <>
                                        <SortableHeader key={`${category}${category}_vendor`} column={`${category}_vendor`} color={categories[category]} label={category} />
                                        <SortableHeader key={`${category}_price`} column={`${category}_price`} color={categories[category]} label={`${category} Price`} />
                                        <SortableHeader key={`${category}_price`} column={`${category}_price`} color={categories[category]} label={`${category} Hours`} />
                                        <SortableHeader key={`${category}_price`} column={`${category}_price`} color={categories[category]} label={`${category} Deliverables`} />
                                        <SortableHeader key={`${category}_price`} column={`${category}_price`} color={categories[category]} label={`${category} Results`} />
                                    </>
                                ))}

                            </tr>
                        </thead>
                        <tbody>
                            <Results />
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default SelectBudget;


const cat = [
    "DJ", "Planner/Coordinator", "Videographer", "Hair/Makeup", "Bakers", "Photographer", "Florist"
]


const categories = {
    "DJ": "bg-blue-100",
    "Planner/Coordinator": "bg-orange-100",
    "Videographer": "bg-purple-300",
    "Hair/Makeup": "bg-pink-200",
    "Bakers": "bg-amber-100",
    "Florist": "bg-teal-100",
    // "Photographer": "bg-gray-100",
}

// const categories = {
//     Photographer: "Photographers",
//     Videographer: "Videographers",
//     Florist: "Florists",
//     DJ: "DJs"
//   };