import React, { useState, useEffect, useContext, useRef } from "react";
import { AnswersContext } from "../../AnswersContext";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import backArrow from '../../styles/back-arrow.svg'
import submitVendor from './submit-vendor.component.js'

const FooterNew = (props) => {

	const { quizState } = useContext(AnswersContext);

	const getInitialHistoryStack = () => {
    const savedHistoryStack = localStorage.getItem("historyStack");
    return savedHistoryStack ? JSON.parse(savedHistoryStack) : [];
  };


	const [ nextStep, setNextStep ] = useState(false)
	const [ currentStep, setCurrentStep] = useState(0)
	const [ quizNavSteps, setQuizNavSteps] = useState()
	const [ nextQuizStep, setNextQuizStep] = useState()
	const [ nextQuizLink, setNextQuizLink] = useState()
	const [ prevQuizStep, setPrevQuizStep] = useState()
	const [ fromDashboard, setFromDashboard ] = useState(false)
	const [historyStack, setHistoryStack] = useState(getInitialHistoryStack());
	const location = useLocation();
	const history = useNavigate();



	const getOrderedVendorTypes = () => {
		return quizState.chosenVendorTypes
				.filter(vendorType => navigationDict[vendorType]?.rankStep) // Ensure the vendorType is in the dictionary and has rankStep: true
				.sort((a, b) => navigationDict[a].rankOrder - navigationDict[b].rankOrder);
	};


	const findNextInQuizStep = (currentStep) => {
		const orderedVendorTypes = getOrderedVendorTypes();
		for (let i = 0; i < orderedVendorTypes.length; i++) {
			const vendorType = orderedVendorTypes[i];
			if (navigationDict[vendorType]?.rankOrder > currentStep) {
				return navigationDict[vendorType];
			}
		}
		return null;
	};


	const handleAddition = async () => {
		try {
			let weddingResponse = await submitVendor(props.phase, quizState);
			if (weddingResponse) {
				history('/home');
				return
			}
		} catch (error) {
			console.error('Error submitting vendor:', error);
		}
	}

	const setDelay = (e) => {
		e.preventDefault(); //prevent transition

		if ((props.phase === 'Photographer') && fromDashboard) {
			history(`${nextQuizLink}?source=dashboard`)
			return
		}

		if ((props.phase === 'coverage') && fromDashboard) {
			history(`/dotogether?source=dashboard`)
			return
		}

		if ((props.phase === 'dotogether') && fromDashboard) {
			history(`/photostyle?source=dashboard`)
			return
		}

		if (fromDashboard && props.phase !== 'Photographer') {
			handleAddition()
			return
		}


		if (navigationDict[props.phase].path) {
			window.setTimeout(() => {
				history(navigationDict[props.phase].to);
		}, 500);
		} else {
			if (navigationDict[props.phase].rankStep) {
				const updatedHistoryStack = [...historyStack, props.phase];
				setHistoryStack(updatedHistoryStack);
				localStorage.setItem("historyStack", JSON.stringify(updatedHistoryStack));
					window.setTimeout(() => {
							history(nextQuizLink);
					}, 500);
			} else {
					window.setTimeout(() => {
							history(navigationDict[props.phase].to);
					}, 500);
			}
		}
	};


	const goBack = (e) => {
		e.preventDefault();

		if (fromDashboard && (props.phase !== 'coverage' && props.phase !== 'dotogether' && props.phase !== 'photostyle')) {
			history(`/home`)
			return
		}

		if ((props.phase === 'coverage') && fromDashboard) {
			history(`/photographer?source=dashboard`)
			return
		}

		if ((props.phase === 'dotogether') && fromDashboard) {
			history(`/howimportant?source=dashboard`)
			return
		}

    const updatedHistoryStack = [...historyStack];
    const previousPage = updatedHistoryStack.pop();
    setHistoryStack(updatedHistoryStack);
    localStorage.setItem("historyStack", JSON.stringify(updatedHistoryStack));

		if (previousPage) {
				window.setTimeout(() => {
					history(navigationDict[previousPage].link)
				}, 500);
		} else {
				window.setTimeout(() => {
						history(navigationDict[props.phase].from);
				}, 500);
		}
	};


	useEffect(() => {

		const searchParams = new URLSearchParams(location.search);
		const source = searchParams.get('source');

		if (source === 'dashboard') {
			setFromDashboard(true)
		}

		if (navigationDict[props.phase].rankStep) {
				const nextStep = findNextInQuizStep(navigationDict[props.phase].rankOrder);
				if (nextStep && navigationDict[props.phase].title !== "Photographer") {
					setNextQuizLink(nextStep.link);
					setNextQuizStep(nextStep.title);
				} else if (!nextStep && navigationDict[props.phase].title === "Photographer") {
					setNextQuizLink(navigationDict[props.phase].to);
					setNextQuizStep("Coverage Priority");
				} else {
					setNextQuizLink("/signup");
					setNextQuizStep("Match Results");
				}
				setCurrentStep(navigationDict[props.phase].rankOrder);
		}
	}, [props.phase]);





	return (
		<div>
			<div className="mobile:hidden w-full flex justify-center space-x-4">
				{navigationDict[props.phase].back &&
					<button className='w-[290px] h-[50px] flex items-center justify-center my-12 rounded-full bg-white border border-[10px] text-[#0A8A95] z-1 ' onClick={(e) => goBack(e)}>
							Back
					</button>
				}

				{props.complete ?
					<button className='w-[290px] h-[50px] flex items-center justify-center my-12 rounded-full bg-[#0A8A95] opacity-100 text-white z-1' onClick={(e) => setDelay(e)}>
							{nextQuizStep ? <p>Next: {nextQuizStep}</p> : <p>{navigationDict[props.phase].next}</p>}
					</button>
					:
					<div className='w-[290px] h-[50px] flex items-center justify-center my-12 rounded-full bg-[#0A8A95] opacity-40 text-white z-1'>
							{nextQuizStep ? <p>Next: {nextQuizStep}</p> : <p>{navigationDict[props.phase].next}</p>}
					</div>
					}
			</div>

			<div className="hidden mobile:flex w-full justify-center">
				<div className="footer-new">
					<div className="footer-back">
						{navigationDict[props.phase].back &&
							<div className={`${navigationDict[props.phase].back ? '' : 'opacity-50'} flex`} onClick={(e) => goBack(e)}>
								<img src={backArrow} style={{marginRight: '10px'}}/>
								<p className="text-[#17A2B8] hover:text-[#007bff] text-sm">Back</p>
							</div>
						}
					</div>

					<div className="footer-right">
						{props.complete &&
						<div>
							<button onClick={props.clickEvent} className={`footer-next-button ${!props.complete ? 'inactive' : ''}`} onClick={(e) => setDelay(e)}>
								<span className="text-[15px]">{nextQuizStep ? <p>Next: {nextQuizStep}</p> : <p>{navigationDict[props.phase].next}</p>}</span>
							</button>
						</div>

						}
						{!props.complete &&
							<div>
								<button className={`footer-next-button ${!props.complete ? 'inactive' : ''}`}>
									<span className="text-[15px]">{nextQuizStep ? <p>Next: {nextQuizStep}</p> : <p>{navigationDict[props.phase].next}</p>}</span>
								</button>
							</div>
						}
					</div>
				</div>
			</div>
		</div>
	);
};

export default FooterNew;






const rankNavOrder = {
	2: 'Budget',
	3: 'Videographer',
	4: 'Florist',
	5: 'DJ',
	6: 'Bakers',
	7: 'Hair/Makeup',
	8: 'Planner/Coordinator',
	9: "Photographer"
}


const navigationDict = {
	"location": {
		"from" : "/",
		"to": "/vendortypes",
		"next": "Next: Vendor Categories",
		"button": true,
		"back": false,
		"rankStep": false,
	},
	"vendorTypes": {
		"from" : "/",
		"to": "/date",
		"next": "Next: Wedding Details",
		"button": true,
		"back": true,
		"rankStep": false,
	},
	"date": {
		"from" : "/vendortypes",
		"to": "/tags",
		"next": "Next: Wedding Attributes",
		"button": true,
		"back": true,
		"rankStep": false,
	},
	'detailsTags': {
		"from" : "/date",
		"to": "/photographer",
		"next": "Next: Photographer Preferences",
		"button": true,
		"back": true,
		"rankStep": true,
		"rankOrder": 0,
		"link": "/tags"
	},
	// EVERYTHING BEFORE STATIC
	'Videographer': {
		"link" : "/videographernewquiz",
		"next": "Next: Florists",
		"button": true,
		"back": true,
		"rankStep": true,
		"rankOrder": 1,
		"title": "Videographer"
	},
	'Florist': {
		"link": "/floristnewquiz",
		"next": "Next: DJs",
		"button": true,
		"back": true,
		"rankStep": true,
		"rankOrder": 2,
		"title": "Florist"
	},
	'DJ': {
		"link": "/djnewquiz",
		"next": "Next: Cake/Desserts",
		"button": true,
		"back": true,
		"rankStep": true,
		"rankOrder": 3,
		"title": "DJ"
	},
	'Bakers': {
		"link" : "/cakedessertnewquiz",
		"next": "Next: Hair/Makeup",
		"button": true,
		"back": true,
		"rankStep": true,
		"rankOrder": 4,
		"title": "Cake/Desserts"
	},
	'Hair/Makeup': {
		"link": "/hairmakeupnewquiz",
		"next": "Next: Planner/Coordinator",
		"button": true,
		"back": true,
		"rankStep": true,
		"rankOrder": 6,
		"title": "Hair/Makeup"
	},
	'Planner/Coordinator': {
		"link": "/plannernewquiz",
		"next": "Next: Match Results",
		"button": true,
		"back": true,
		"rankStep": true,
		"rankOrder": 7,
		"title": "Planner/Coordinator"
	},
	'Photographer': {
		"link": "/photographer",
		"to": "/howimportant",
		"next": "Next: Coverage Priority",
		"button": true,
		"back": true,
		"path": true,
		"rankStep": true,
		"rankOrder": 8,
		"title": "Photographer"
	},
	'coverage': {
		"from" : "/photographer",
		"to": "/dotogether",
		"next": "Next: Photographer Attributes",
		"button": true,
		"back": true,
		"rankStep": false,
		"rankOrder": 9
	},
	'dotogether': {
		"from" : "/howimportant",
		"to": "/photostyle",
		"next": "Next: Photographer Style",
		"button": true,
		"back": true,
		"rankStep": false,
		"rankOrder": 10
	},
	'Photostyle': {
		"from" : "/dotogether",
		"to": "/",
		"next": "Next: Coverage Priority",
		"button": true,
		"back": true,
		"rankStep": true,
		"rankOrder": 11,
		"inQuiz": false,
		"path": true,
		"link": "/photographernewquiz",
		"name": "Next: Photographer Preferences"
	},
	// 'Photographer': {
	// 	"from" : "/tags",
	// 	"to": "/howimportant",
	// 	"next": "Next: Coverage Priority",
	// 	"button": true,
	// 	"back": true,
	// 	"rankStep": true,
	// 	"rankOrder": 8,
	// 	"inQuiz": false,
	// 	"path": true,
	// 	"link": "/photographernewquiz",
	// 	"name": "Next: Photographer Preferences"
	// },
}



















































// import React, { useState, useEffect, useContext } from "react";
// import { AnswersContext } from "../../AnswersContext";
// import { useNavigate } from "react-router-dom";
// import { Link } from "react-router-dom";
// import backArrow from '../../styles/back-arrow.svg'

// const FooterNew = (props) => {

// 	const { quizState } = useContext(AnswersContext);

// 	const [ nextStep, setNextStep ] = useState(true)
// 	const [ currentStep, setCurrentStep] = useState(0)
// 	const [ quizNavSteps, setQuizNavSteps] = useState()
// 	const history = useNavigate();

// 	const getOrderedVendorTypes = () => {
// 		return quizState.chosenVendorTypes
// 		.filter(vendorType => navigationDict[vendorType]?.rankStep) // Ensure the vendorType is in the dictionary and has rankStep: true
// 		.sort((a, b) => navigationDict[a].rankOrder - navigationDict[b].rankOrder);
// 	}

// 	useEffect(() => {
// 		setCurrentStep(navigationDict[props.phase].rankOrder)
// 		setQuizNavSteps(getOrderedVendorTypes())
// 	}, [props.phase])


// 	const rankNavOrder = {
// 		1: 'details',
// 		2: 'Budget',
// 		3: 'Videographer',
// 		4: 'Florist',
// 		5: 'DJ',
// 		6: 'Bakers'

// 	}


	// const navigationDict = {
	// 	"location": {
	// 		"from" : "/",
	// 		"to": "/vendortypes",
	// 		"next": "Next: Vendor Categories",
	// 		"button": true,
	// 		"back": false,
	// 		"rankStep": false,
	// 		"rankOrder": 0
	// 	},
	// 	"vendorTypes": {
	// 		"from" : "/",
	// 		"to": "/date",
	// 		"next": "Next: Wedding Details",
	// 		"button": true,
	// 		"back": true,
	// 		"rankStep": false,
	// 		"rankOrder": 0
	// 	},
	// 	"date": {
	// 		"from" : "/vendortypes",
	// 		"to": "/tags",
	// 		"next": "Next: Wedding Attributes",
	// 		"button": true,
	// 		"back": true,
	// 		"rankStep": false,
	// 		"rankOrder": 0
	// 	},
	// 	'detailsTags': {
	// 		"from" : "/date",
	// 		"to": "/photographer",
	// 		"next": "Next: Photographer Preferences",
	// 		"button": true,
	// 		"back": true,
	// 		"rankStep": true,
	// 		"rankOrder": 0
	// 	},
	// 	'budget': {
	// 		"from" : "/tags",
	// 		"to": "/howimportant",
	// 		"next": "Next: Coverage Priority",
	// 		"button": true,
	// 		"back": true,
	// 		"rankStep": true,
	// 		"rankOrder": 1
	// 	},
	// 	'coverage': {
	// 		"from" : "/photographer",
	// 		"to": "/dotogether",
	// 		"next": "Next: Photographer Attributes",
	// 		"button": true,
	// 		"back": true,
	// 		"rankStep": true,
	// 		"rankOrder": 1
	// 	},
	// 	'dotogether': {
	// 		"from" : "/howimportant",
	// 		"to": "/photostyle",
	// 		"next": "Next: Photographer Style",
	// 		"button": true,
	// 		"back": true,
	// 		"rankStep": true,
	// 		"rankOrder": 1
	// 	},
	// 	'Photostyle': {
	// 		"from" : "/dotogether",
	// 		"to": "/",
	// 		"next": "Next: Coverage Priority",
	// 		"button": true,
	// 		"back": true,
	// 		"rankStep": true,
	// 		"rankOrder": 8,
	// 		"inQuiz": false,
	// 		"path": true,
	// 		"link": "/photographernewquiz",
	// 		"name": "Next: Photographer Preferences"
	// 	},
	// 	'Videographer': {
	// 		"from" : "/tagsnewquiz",
	// 		"to": "/floristnewquiz",
	// 		"next": "Next: Florists",
	// 		"button": true,
	// 		"back": true,
	// 		"rankStep": true,
	// 		"rankOrder": 2
	// 	},
	// 	'florist': {
	// 		"from" : "/videographernewquiz",
	// 		"to": "/djnewquiz",
	// 		"next": "Next: DJs",
	// 		"button": true,
	// 		"back": true,
	// 		"rankStep": true,
	// 		"rankOrder": 3
	// 	},
	// 	'DJ': {
	// 		"from" : "/floristnewquiz",
	// 		"to": "/cakedessertnewquiz",
	// 		"next": "Next: Cake/Desserts",
	// 		"button": true,
	// 		"back": true,
	// 		"rankStep": true,
	// 		"rankOrder": 4
	// 	},
	// 	'Bakers': {
	// 		"from" : "/djnewquiz",
	// 		"to": "/hairmakeupnewquiz",
	// 		"next": "Next: Hair/Makeup",
	// 		"button": true,
	// 		"back": true,
	// 		"rankStep": true,
	// 		"rankOrder": 5
	// 	},
	// 	'hairMakeup': {
	// 		"from" : "/djnewquiz",
	// 		"to": "/plannernewquiz",
	// 		"next": "Next: Planner/Coordinator",
	// 		"button": true,
	// 		"back": true,
	// 		"rankStep": true,
	// 		"rankOrder": 6
	// 	},
	// 	'Planner/Coordinator': {
	// 		"from" : "/djnewquiz",
	// 		"to": "/plannernewquiz",
	// 		"next": "Next: Match Results",
	// 		"button": true,
	// 		"back": true,
	// 		"rankStep": true,
	// 		"rankOrder": 7
	// 	},
	// 	'Photographer': {
	// 		"from" : "/tags",
	// 		"to": "/howimportant",
	// 		"next": "Next: Coverage Priority",
	// 		"button": true,
	// 		"back": true,
	// 		"rankStep": true,
	// 		"rankOrder": 8,
	// 		"inQuiz": false,
	// 		"path": true,
	// 		"link": "/photographernewquiz",
	// 		"name": "Next: Photographer Preferences"
	// 	},
	// }

// 	const setDelay = (e) => {
// 		e.preventDefault(); //prevent transition
// 		window.setTimeout(() => {
// 			history(navigationDict[props.phase].to);
// 			// if (currentStep > 0) {
// 			// 	let nextNav = currentStep + 1;
// 			// 	while (nextNav <= 9) {
// 			// 		if (quizNavSteps.indexOf(nextNav) > -1) {
// 			// 			history.push(navigationDict[rankNavOrder[nextNav]].to);
// 			// 			break;
// 			// 		} else {
// 			// 			nextNav += 1;
// 			// 		}
// 			// 	}
// 			// 	console.log('end')
// 			// } else {
// 			// 	history(navigationDict[props.phase].to);
// 			// }
// 			// copy chosenvendortypes
// 			// rank chosenvendortypes
// 			// if navstep > 0
// 			// take vendortypes[0]
// 			// history.push(vendortypes[0].to)
// 			// delete vendorstep from copy array
// 			//  else
// 			// navigationDict[props.phase].to
// 		}, 500);
// 	};

// 	const goBack = (e) => {
// 			e.preventDefault(); //prevent transition
// 			window.setTimeout(() => {
// 					history(navigationDict[props.phase].from);
// 			}, 500);
// 	};


// 	return (
// 		<div>
// 			<div className="mobile:hidden w-full flex justify-center space-x-4">
// 				{navigationDict[props.phase].back &&
// 					<button className='w-[290px] h-[50px] flex items-center justify-center my-12 rounded-full bg-white border border-[10px] text-[#0A8A95] z-1 ' onClick={(e) => goBack(e)}>
// 							Back
// 					</button>
// 				}

// 				{props.complete ?
// 					<button className='w-[290px] h-[50px] flex items-center justify-center my-12 rounded-full bg-[#0A8A95] opacity-100 text-white z-1' onClick={(e) => setDelay(e)}>
// 							{navigationDict[props.phase].next}
// 					</button>
// 					:
// 					<div className='w-[290px] h-[50px] flex items-center justify-center my-12 rounded-full bg-[#0A8A95] opacity-40 text-white z-1'>
// 							{navigationDict[props.phase].next}
// 					</div>
// 					}
// 			</div>

// 			<div className="hidden mobile:flex w-full justify-center">
// 				<div className="footer-new">
// 					<div className="footer-back">
// 						{navigationDict[props.phase].back &&
// 							<Link to={navigationDict[props.phase].from} className={`${navigationDict[props.phase].back ? '' : 'opacity-50'} flex`} >
// 								<img src={backArrow} style={{marginRight: '10px'}}/>
// 								<p className="text-[#17A2B8] hover:text-[#007bff] text-sm">Back</p>
// 							</Link>
// 						}
// 					</div>

// 					<div className="footer-right">
// 						{props.noLink &&
// 						<div>
// 							<button onClick={props.clickEvent} className={`footer-next-button ${!props.complete ? 'inactive' : ''}`}>
// 								<span className="text-xs">{navigationDict[props.phase].next}</span>
// 							</button>
// 						</div>

// 						}
// 						{!props.noLink &&
// 							<div>
// 								<Link to={navigationDict[props.phase].to}>
// 									<button className={`footer-next-button ${!props.complete ? 'inactive' : ''}`}>
// 										<span className="text-xs">{navigationDict[props.phase].next}</span>
// 									</button>
// 								</Link>
// 							</div>
// 						}
// 					</div>
// 				</div>
// 			</div>
// 		</div>
// 	);
// };

// export default FooterNew;
