import React, { useState, useEffect } from 'react'
import Nav from './nav.component'
import axios from 'axios'
import Info from './info.component'
import Matches from './matches.component'
import Calendar from './calendar.component'
import Pricing from './pricing.component'
import Photos from './photos.component'
import Albums from './albums.component'
import PreferredVendors from './preferredVendors.component'
import { useParams } from "react-router-dom";

export default function Dashboard() {
    const [ tab, setTab ] = useState('My Profile')
    const [ vendor, setVendor ] = useState(null)
    const [ originalValues, setOriginalValues ] = useState(null)
    const { vendorId } = useParams()
    // const testVendorId = useParams()
    const [ authorized, setAuthorized ] = useState(true)
    const queryParams = new URLSearchParams(window.location.search)
    const from = queryParams.get("from")
    const email = queryParams.get("email")
    useEffect(() => {
        axios.get(`/auth/get-vendor-user${vendorId ? `/${vendorId}` : ''}`)
        .then(r => {
            if (r.data.msg === 'Not Authorized') {
                setAuthorized(false)
            }
            setVendor(r.data)
            setOriginalValues(r.data)
        })
    }, [vendorId])
    const NeededComponent = components[tab]
    const referralCode = vendor?.business_name?.replace(/[^a-zA-Z ]/g, "").toLowerCase().replace('  ', ' ').split(' ').filter(s => s !== 'and' && s !== 'the' && s !== 'of' && s !== 'by').slice(0,2).join('')
    if (authorized && !from) {
        return (
            <div className='vendor-dashboard'>
                <Nav tab={tab} setTab={setTab} />
                <div>
                    <div className='vendor-referral-banner'>Send couples to WedMatch and receive half of any referral fees we collect when they book. - &nbsp;<a style={{color: 'inherit'}} href={`https://wedmatch.com?${referralCode}`}>wedmatch.com?{referralCode}</a></div>
                    <div className='vendor-dashboard-body'>
                    {vendor && originalValues && <NeededComponent originalValues={originalValues} setVendor={setVendor} vendor={vendor} />}
                    </div>
                </div>
            </div>
        )
    } else if (from) {
        return (
            <div style={{display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center', fontSize: '36px'}}>
                <div style={{maxWidth: '100%', padding: '6px'}}>It looks like we already have some information on this business. We've sent an email to {email} to claim your account and login to your dashboard.</div>
            </div>
        )
    } else {
        return (
            <div>Account not authorized. Check the email address associated with the vendor or contact us at help@wedmatch.com for assistance.</div>
        )
    }
}

const components = {
    'My Profile': Info,
    'Home': Info,
    'Matches': Matches,
    'Portfolio': Photos,
    'Real Weddings': Albums,
    'Pricing': Pricing,
    'Logout': Info,
    'Calendar': Calendar,
    'Preferred Vendors': PreferredVendors
}