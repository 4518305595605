import React, { useState, useEffect } from 'react';
import axios from 'axios';


const NotificationPopup = ({ r, setOpenNotification, userId, userProfiles, enrichedData, styleTags }) => {


    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()
    const [coupleEmail, setCoupleEmail] = useState()
    const [vendor, setVendor] = useState()


    const filteredUserProfiles = () => {
        const matchedProfileData = userProfiles?.find(profile => profile.id === userId)
        const matchedWeddingData = enrichedData[userId]
        setFirstName(matchedProfileData.first_name)
        setLastName(matchedProfileData.last_name)
        setCoupleEmail(matchedProfileData.email)
    }



    const populateDataVariables = (requiredFields, dataVariableDic, r, vendor, allowVendorContact, vendorCategoryData, matchedTags) => {
        const dataVariables = {
            // "vendor-name": 'test',
            "couple-name": `${firstName} ${lastName}`,
            "wedding-location": locationDic[r.wedding_location] || 'Undecided',
            "wedding-tags": matchedTags || 'Undecided',
            "wedding-date": `${r.month}/${r.day}/${r.year}`  || 'Undecided',
            "wedding-venue": r.venue_name || 'Undecided',
        };

        if (allowVendorContact) {
            dataVariables["couple-email"] = r.email
        }

        console.log(dataVariables)

        requiredFields.forEach((field) => {
            const fieldMapping = dataVariableDic[field];
            let value;

            if (fieldMapping) {
                try {
                    value = fieldMapping.replace(/\$\{(.+?)\}/g, (_, match) => {
                        const evaluatedValue = eval(match);
                        return evaluatedValue !== undefined && evaluatedValue !== null ? evaluatedValue.join(", ") : "Undecided";
                    });
                } catch (error) {
                    value = "Undecided";
                }
            } else {
                value = "Undecided";
            }
            dataVariables[field] = value;
        });

        return dataVariables;
    };



    const updateVisibility = (send) => {
        try {
            const response = axios.post('/api/results-is-visible', {
                userId,
                send
            });
        } catch (error) {
            console.error(`error updating visible`);
        }
    }


    const sendVendorEmails = () => {
        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms)); // Helper function for delay
        let allowVendorContact = true
        let vendorEmails = generateEmails()

        for (const category in vendorEmails) {
            const categoryEmails = vendorEmails[category];

            if (categoryEmails) {
                const vendorCategoryData = enrichedData[userId]?.find((data) => data.vendor_category === category.toLowerCase());
                try {
                    const transactionalId = vendorEmailConfigs[category].allowedTransactionIds[allowVendorContact];
                    const requiredFields = vendorEmailConfigs[category].requiredFields;
                    let matchedTags = generateTags().join(", ")

                    const dataVariables = populateDataVariables(requiredFields, dataVariableDic, r, category, allowVendorContact, vendorCategoryData, matchedTags);
                    let allSuccessful = true

                    categoryEmails.forEach(async (email) => {
                        try {
                            const response = await axios.post('/api/test-loops-all', {
                                transactionalId,
                                email,
                                dataVariables,
                            });
                            console.log(response, dataVariables, email)
                        } catch (error) {
                            allSuccessful = false;
                            console.error(`Error sending email request for ${email} to backend:`, error.response?.data || error.message);
                        }

                        await delay(2000)
                    });

                    if (allSuccessful) {
                        updateVisibility(true)
                    }

                } catch (error) {
                    console.error(`Error processing category ${category}:`, error);
                }
            }
        }
    };



    const generateEmails = () => {
        const emailsByCategory = Object.keys(categories).reduce((acc, category) => {
            const vendorData = enrichedData[userId]?.find(
                (data) => data.vendor_category === category.toLowerCase()
            );

            const contactEmails =
                vendorData?.scored_results
                    ?.sort((a, b) => b.totalScore - a.totalScore)
                    .slice(0, 5)
                    .map((result) => result.contact_email) || [];

            acc[category.toLowerCase()] = contactEmails;
            return acc;
        }, {});

        return emailsByCategory;
    };


    const saveResults = () => {
        const resultArray = []
        const resultByCategory = Object.keys(categories).reduce((acc, category) => {
            const vendorData = enrichedData[userId]?.find(
                (data) => data.vendor_category === category.toLowerCase()
            );

            const visibleResults =
                vendorData?.scored_results
                    ?.sort((a, b) => b.totalScore - a.totalScore)
                    .slice(0, 5)
                    .map((result) => (
                        {
                            "result_fkey": vendorData.id,
                            "vendor_fkey": result.id,
                            "status": "Not Booked",
                            "invoiced": null
                        })) || [];
            resultArray.push(visibleResults.flat())
        }, {});

        let allResults = resultArray.flat()

        allResults.forEach(async (individualResult) => {
            try {
                const response = await axios.post('/api/results-save-matched', {
                    results: individualResult
                });
            } catch (error) {
                console.error('save failed');
            }
        })
    }


    const generateTags = () => {
        const tagArrays = ["important_tags", "location_tags", "style_tags", "user_only_tags"]

        const mergedTags = tagArrays
            .map(tag => r[tag])
            .flat();

        const matchedTags = mergedTags.map(id => {
            const tag = styleTags.find(tag => tag.id === id); // Find the tag with the matching ID
            return tag ? tag.name : null; // Return the name or null if not found
            });

        return matchedTags
    }



    useEffect(() => {
        generateTags()
        filteredUserProfiles()
    }, [])



    return (
        <div className="absolute left-24 w-[180px] flex flex-col items-center space-y-3 p-3 bg-white border shadow rounded-3xl">
            <button onClick={() => sendVendorEmails()}>test</button>
            <p>User: {r?.id}</p>
            <p className="text-xs">{firstName} {lastName}</p>
            <button
                className="px-2 py-1 bg-yellow-500 rounded-xl"
                onClick={() => {
                    setOpenNotification(null);
                }}
            >
                Select
            </button>
            <button
                className="px-2 py-1 bg-green-500 text-white rounded-xl"
                onClick={() => {
                    setOpenNotification(null);
                    saveResults();
                    sendVendorEmails()
                }}
            >
                Send Emails
            </button>
            <button
                className="px-2 py-1 bg-red-500 text-white rounded-xl"
                onClick={() => {
                    updateVisibility(false)
                    setOpenNotification(null);
                }}
            >
                Don't Send Emails
            </button>
        </div>
    );
};

export default NotificationPopup


const categories = {
    "dj": "bg-blue-100",
    "planner/coordinator": "bg-orange-100",
    "videographer": "bg-purple-300",
    "hair/makeup": "bg-pink-200",
    "bakers": "bg-amber-100",
    "florist": "bg-teal-100",
}



const vendorEmailConfigs = {
    "videographer": {
        allowedTransactionIds: {
            true: 'cm22gig1n007z2bpu12iexclk',
            false: 'cm22i1k4f0064yl1w1mypumus'
        },
        requiredFields:
            [
                'couple-email',
                'videographer-budget', 'videographer-coverage'
            ]
    },
    "florist": {
        allowedTransactionIds: {
            true: 'cm22ieins002313ayevzj5gd4',
            false: 'cm22ilneb005mz2te4zlyf84p'
        },
        requiredFields:
            [
                'florist-budget', 'floral-arrangement-types'
            ]
    },
    "dj": {
        allowedTransactionIds: {
            true: 'cm2jma5tl000h6z49db7xvv27',
            false: 'cm2jm9r1u001wbuc67vd6mzo0'
        },
        requiredFields:
            [
                'dj-budget', 'dj-coverage', 'dj-services'
            ]
    },
    "bakers": {
        allowedTransactionIds: {
            true: 'cm2jmfnem000fza130da4t9hd',
            false: 'cm2jmhio6006t81krwpfhwr15'
        },
        requiredFields:
            [
                'cake-budget'
            ]
    },
    "hair/makeup": {
        allowedTransactionIds: {
            true: 'cm2jmlk3r007l90bwbjh377wv',
            false: 'cm2jmpt0j00g0plcdhbb1yg6j'
        },
        requiredFields:
            [
                'hairmakeup-budget', 'hairmakeup-services', 'bridesmaid-count'
            ]
    },
    "planner/coordinator": {
        allowedTransactionIds: {
            true: 'cm2jms41a00emhbsiw9bvbvlh',
            false: 'cm2jmtwx100faw049ygh6mj2x'
        },
        requiredFields:
            [
                'planner-budget', 'planner-types'
            ]
    }
};



const dataVariableDic = {
    'dj-budget': "${vendorCategoryData.photographer_min_max[0]} to ${vendorCategoryData.photographer_min_max[1]}",
    'dj-coverage': "${vendorCategoryData.photographer_num_hours}",
    'dj-services': "${vendorCategoryData.photographer_parts_day}",
    'videographer-budget': "${vendorCategoryData.photographer_min_max[0]} to ${vendorCategoryData.photographer_min_max[1]}",
    'videographer-coverage' : "${vendorCategoryData.photographer_num_hours}",
    "cake-budget" : "${vendorCategoryData.photographer_min_max[0]} to ${vendorCategoryData.photographer_min_max[1]}",
    "florist-budget": "${vendorCategoryData.photographer_min_max[0]} to ${vendorCategoryData.photographer_min_max[1]}",
    "floral-arrangement-types": "",
    'hairmakeup-budget':  "${vendorCategoryData.photographer_min_max[0]} to ${vendorCategoryData.photographer_min_max[1]}",
    'hairmakeup-services': "${vendorCategoryData.photographer_parts_day}",
    "bridesmaid-count": "${vendorCategoryData.photographer_parts_day}",
    "planner-budget": "${vendorCategoryData.photographer_min_max[0]} to ${vendorCategoryData.photographer_min_max[1]}",
    'planner-types': "${vendorCategoryData.photographer_parts_day}",
}


const locationDic = {
    "1": "Richmond, VA",
    "2": "Charlottesville, VA",
    "3": "Washington, DC",
    "4": "Virginia Beach, VA"
}


