import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { locationDic } from '../../utils/locationDic'

export default function Matches({ vendor }) {

    const [matches, setMatches] = useState([]);
    console.log(vendor)

    const extractDate = (date) => {
      return date.replace(/^(\d{4})-(\d{2})-(\d{2}).*$/, "$2/$3/$1");
    }

    useEffect(() => {
      const fetchMatches = async () => {
        try {
          const response = await axios.get('/api/results-get-matched');
          const filteredMatches = response.data.data.filter(match => match.result_status.vendor_fkey === vendor.id);
          console.log(filteredMatches)
          setMatches(filteredMatches);
        } catch (error) {
          console.error('Error fetching matches:', error);
        }
      };
      fetchMatches();
    }, []);



    return (
        <div className="bg-white rounded-lg p-6 max-w-4xl w-full">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold">Matches Dashboard</h2>
          </div>
          {matches.length === 0 ? (
            <p className="text-gray-500 text-center py-8">No matches found.</p>
          ) : (
            <table className="w-full border-collapse">
              <thead>
                <tr className="bg-gray-100 text-gray-700">
                  <th className="px-4 py-1.5 text-left">Match Date</th>
                  <th className="truncate px-4 py-1.5 text-left">Wedding Date</th>
                  <th className="truncate px-4 py-1.5 text-left w-1/4">Wedding Location</th>
                  <th className="truncate px-4 py-1.5 text-left">Venue Name</th>
                  <th className="px-4 py-1.5 text-left">Email</th>
                  {/* <th className="px-4 py-1.5 text-right">Status</th> */}
                </tr>
              </thead>
              <tbody>
                {matches.map((match, index) => (
                  <tr
                    key={index}
                    className={`border-b border-gray-200 hover:bg-gray-100 ${
                      index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                    }`}
                  >
                    <td className="px-4 py-3 text-left">{extractDate(match.result_status.created_at)}</td>
                    <td className="px-4 py-3 text-left">
                      {match.wedding_preferences.month}/{match.wedding_preferences.day}/{match.wedding_preferences.year}
                    </td>
                    <td className="px-4 py-3 text-left truncate max-w-xs">
                      {locationDic[match.wedding_preferences.wedding_location]}
                    </td>
                    <td className="px-4 py-3 text-left truncate max-w-xs">
                      <div className="truncate">{match.wedding_preferences.venue_name}</div>
                    </td>
                    <td className="px-4 py-3 text-left">{match.wedding_preferences.email}</td>
                    {/* <td className="px-4 py-3 text-right">
                      <div className="flex justify-end">
                        <button className="bg-gray-400 hover:bg-greenblue text-white font-bold px-2 py-1 rounded mr-2">
                          Yes
                        </button>
                        <button className="bg-gray-400 hover:bg-greenblue text-white font-bold px-2 py-1 rounded mr-2">
                          No
                        </button>
                        <button className="max-w-xs truncate bg-gray-400 hover:bg-greenblue text-white font-bold px-2 py-1 rounded">
                          Still Talking
                        </button>
                      </div>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
    );
}