import axios from "axios";
import React, { useEffect, useState } from "react";
import ModalWindow from './modal.component'
import EditVendor from "./edit-vendor.component";

export default function VendorList() {
    const [ vendors, setVendors ] = useState([])
    const [locations, setLocations] = useState([])
    const [ vendorEditing, setVendorEditing ] = useState(null)

    const columns = [
        {
            title: 'Name',
            field: 'business_name'
        },
        {
            title: 'ID',
            field: 'id'
        },
        {
            title: 'Email',
            field: 'contact_email'
        },
        {
            title: 'Account Type',
            field: 'account_type'
        },
        {
            title: 'Edit',
            field: 'id',
            value: (value) =>
                <a href="#" onClick={() => setVendorEditing(value)}>Edit</a>
        },
        {
            title: 'Primary Location',
            field: 'primary_location',
            value: (value) => locations.find(l => l.id == value)?.name,
        },
        {
            title: 'Secondary Location',
            field: 'secondary_location',
            value: (value) => locations.find(l => l.id == value)?.name,
        },
        {
            title: 'Third Location',
            field: 'third_location',
            value: (value) => locations.find(l => l.id == value)?.name,
        }
    ];

    useEffect(() => {
        axios.get('/api/vendor-accounts')
        .then(r => {
            // Sort vendors by ID when loaded
            const sortedVendors = r.data.sort((a, b) => b.id - a.id);
            console.log(sortedVendors)
            setVendors(sortedVendors)
        })
        document.title="Vendor List | WedMatch"
        axios.get('/auth/getuser')
        .then(r => {
            if(!r.data.admin){
                window.location="/login"
            }
        })
        axios.get('/api/locations')
        .then(r => {
            setLocations(r.data)
        })
    }, [])

    return (
        <div>
            {vendorEditing && <ModalWindow
                Child={<EditVendor
                    vendor={vendors.find(p => p.id == vendorEditing)}
                    locations={locations} />}
                close={() => setVendorEditing(null)}
                     />}
            <table>
                <thead>
                    <tr>
                        {columns.map(c => (
                            <th key={c.title}>{c.title}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {vendors.map(v =>
                        <tr key={v.id}>
                            {columns.map(c => (
                                <td key={c.field}>
                                    {c.value ? c.value(v[c.field]) : v[c.field]}
                                </td>
                            ))}
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}